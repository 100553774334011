import axios from "axios";
import { PAYOUT_SUCCESS, PAYOUT_FAILURE } from "../actionTypes";
import { toast } from "react-toastify";
import { getToastMessage } from "./statusMessages"; // Adjust the path as needed

export const initiatePayout = (
  payload,
  setShowSuccessPayout,
  setInsufficientB,
  setShowFailedPayout,
  onSuccess
) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwtToken");

    try {
      const response = await axios.post(
        "https://testapi.ipaisa.co.in/myapp/auth/instantpay/payout",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const status = response.status;

      // Handle successful payout
      if (status === 200) {
        dispatch({ type: PAYOUT_SUCCESS, payload: response.data });
        toast.success(getToastMessage(status));
        setShowSuccessPayout(true); // Show success popup
        if (onSuccess) {
          onSuccess(); // Call the success callback if provided
        }
      } else if (status === 402) {
        toast.success(getToastMessage(status));
        dispatch({ type: PAYOUT_FAILURE, payload: getToastMessage(status) });
        setInsufficientB(true);
      } else {
        // For other status codes
        toast.error(getToastMessage(status));
        dispatch({ type: PAYOUT_FAILURE, payload: getToastMessage(status) });
        setShowFailedPayout(true);
      }
    } catch (error) {
      console.error("Payout failed:", error);
      const status = error.response ? error.response.status : null; // Get status from error if available
      toast.error(getToastMessage(status, "Payout failed. Please try again."));
      setShowFailedPayout(true);
      dispatch({ type: PAYOUT_FAILURE, payload: error.message });
    }
  };
};
