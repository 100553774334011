import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  Grid,
  Box,
  Button,
  Typography,
  Link,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import { BrowserView, MobileView } from "react-device-detect";

const UploadBox = styled(Box)({
  border: "2px dashed #ccc",
  borderRadius: "8px",
  padding: "16px",
  textAlign: "center",
  margin: "8px",
  width: "100%",
  height: "150px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const Preview = styled("iframe")({
  width: "100%",
  height: "100%",
  border: "none",
  borderRadius: "8px",
});

const KYCVerification = () => {
  const theme = useTheme();
  const [documents, setDocuments] = useState({
    photo: null,
    pan: null,
    aadhaar: null,
    agreement: null,
  });

  const [pendingDocument, setPendingDocument] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (file) => {
    if (!file) return;

    setIsUploading(true);
    if (file.size <= 4 * 1024 * 1024 && file.type === "application/pdf") {
      const reader = new FileReader();
      reader.onloadend = () => {
        setDocuments((prevDocs) => ({
          ...prevDocs,
          [pendingDocument]: reader.result,
        }));
        setPendingDocument("");
        setIsUploading(false);
      };
      reader.readAsDataURL(file);
    } else {
      setIsUploading(false);
      alert("Please upload a PDF file smaller than 4 MB.");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        handleFileChange(acceptedFiles[0]);
      }
    },
    accept: "application/pdf",
    maxSize: 4 * 1024 * 1024,
  });

  const handlePendingDocumentChange = (event) => {
    setPendingDocument(event.target.value);
  };

  const handleDelete = (type) => {
    setDocuments((prevDocs) => ({
      ...prevDocs,
      [type]: null,
    }));
  };

  const remainingDocuments = Object.keys(documents).filter(
    (type) => !documents[type]
  );

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(documents)) {
      if (value) {
        const file = dataURLtoFile(value, `${key}.pdf`);
        formData.append(key, file);
      }
    }

    try {
      const token = localStorage.getItem("jwtToken");
      if (!token) {
        console.error("No JWT token found");
        return;
      }

      const response = await axios.post(
        "https://testapi.ipaisa.co.in/myapp/auth/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Files uploaded successfully");
        // Handle successful upload
      } else {
        console.error("Failed to upload files");
        // Handle error
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      // Handle error
    }
  };

  const handleCancel = () => {
    setDocuments({
      photo: null,
      pan: null,
      aadhaar: null,
      agreement: null,
    });
    setPendingDocument("");
  };

  return (
    <Container style={{ marginBottom: "10%", marginTop: "6%" }}>
      <Box textAlign="center" padding="16px">
        <Typography variant="h5" gutterBottom>
          KYC Verification
        </Typography>
      </Box>
      <BrowserView>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <UploadBox {...getRootProps()}>
              <input {...getInputProps()} />
              {isUploading ? (
                <CircularProgress />
              ) : (
                <Typography variant="body1">
                  Drag & drop a PDF file here, or click to select a file
                </Typography>
              )}
            </UploadBox>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="pending-document-label">
                Select Document
              </InputLabel>
              <Select
                labelId="pending-document-label"
                id="pending-document"
                value={pendingDocument}
                label="Select Document"
                onChange={handlePendingDocumentChange}
              >
                {remainingDocuments.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              component="span"
              disabled={!pendingDocument || isUploading}
              onClick={() => document.querySelector("#file-upload").click()}
              sx={{
                backgroundColor:
                  !pendingDocument || isUploading ? "default" : "#027BFE",
                "&:hover": {
                  backgroundColor:
                    !pendingDocument || isUploading ? "default" : "#0263d4",
                },
              }}
            >
              Upload
            </Button>
            <input
              id="file-upload"
              accept="application/pdf"
              style={{ display: "none" }}
              type="file"
              onChange={(event) => handleFileChange(event.target.files[0])}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              sx={{ color: theme.palette.text.disabled, fontSize: "16px" }}
            >
              Uploaded Documents
            </Typography>
            <Grid container spacing={2} style={{ marginTop: "0.1%" }}>
              {Object.keys(documents).map(
                (type) =>
                  documents[type] && (
                    <Grid item xs={12} sm={6} md={3} key={type}>
                      <Box textAlign="center">
                        <Typography variant="body1">
                          {type.charAt(0).toUpperCase() + type.slice(1)}
                          <IconButton onClick={() => handleDelete(type)}>
                            <DeleteIcon />
                          </IconButton>
                        </Typography>
                        <Preview
                          src={documents[type]}
                          title={`${type} preview`}
                        />
                        <Link
                          href={documents[type]}
                          download={`${type}.pdf`}
                          variant="body2"
                        >
                          Download{" "}
                          {type.charAt(0).toUpperCase() + type.slice(1)}
                        </Link>
                      </Box>
                    </Grid>
                  )
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" gap={4} height="135%">
              <Button
                style={{
                  backgroundColor: "#FF0000",
                  color: "#ffffff",
                  textTransform: "none",
                  width: "50%",
                  borderRadius: "9px",
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "#027BFE",
                  color: "white",
                  fontWeight: "bold",
                  textTransform: "none",
                  width: "50%",
                  borderRadius: "9px",
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </BrowserView>
      <MobileView>
        {/* <Box textAlign="center" padding="16px">
                    <Typography variant="h6" gutterBottom>
                        KYC Verification
                    </Typography>
                </Box> */}
        <UploadBox {...getRootProps()}>
          <input {...getInputProps()} />
          {isUploading ? (
            <CircularProgress />
          ) : (
            <Typography variant="body2">
              Drag & drop a PDF file here, or click to select a file
            </Typography>
          )}
        </UploadBox>
        <FormControl fullWidth>
          <InputLabel id="pending-document-label-mobile">
            Select Document
          </InputLabel>
          <Select
            labelId="pending-document-label-mobile"
            id="pending-document-mobile"
            value={pendingDocument}
            label="Select Document"
            onChange={handlePendingDocumentChange}
          >
            {remainingDocuments.map((type) => (
              <MenuItem key={type} value={type}>
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          component="span"
          disabled={!pendingDocument || isUploading}
          onClick={() => document.querySelector("#file-upload-mobile").click()}
          sx={{
            backgroundColor:
              !pendingDocument || isUploading ? "default" : "#027BFE",
            "&:hover": {
              backgroundColor:
                !pendingDocument || isUploading ? "default" : "#0263d4",
            },
          }}
        >
          Upload
        </Button>
        <input
          id="file-upload-mobile"
          accept="application/pdf"
          style={{ display: "none" }}
          type="file"
          onChange={(event) => handleFileChange(event.target.files[0])}
        />
        <Typography
          gutterBottom
          sx={{ color: theme.palette.text.disabled, fontSize: "14px" }}
        >
          Uploaded Documents
        </Typography>
        <Grid container spacing={2} style={{ marginTop: "0.1%" }}>
          {Object.keys(documents).map(
            (type) =>
              documents[type] && (
                <Grid item xs={12} key={type}>
                  <Box textAlign="center">
                    <Typography variant="body2">
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                      <IconButton onClick={() => handleDelete(type)}>
                        <DeleteIcon />
                      </IconButton>
                    </Typography>
                    <Preview src={documents[type]} title={`${type} preview`} />
                    <Link
                      href={documents[type]}
                      download={`${type}.pdf`}
                      variant="body2"
                    >
                      Download {type.charAt(0).toUpperCase() + type.slice(1)}
                    </Link>
                  </Box>
                </Grid>
              )
          )}
        </Grid>
        <Box display="flex" flexDirection="column" gap={2} alignItems="center">
          <Button
            style={{
              backgroundColor: "#E3E4E6",
              color: "#808080",
              textTransform: "none",
              width: "80%",
              borderRadius: "9px",
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#027BFE",
              color: "white",
              fontWeight: "bold",
              textTransform: "none",
              width: "80%",
              borderRadius: "9px",
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </MobileView>
    </Container>
  );
};

export default KYCVerification;
