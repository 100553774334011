import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import { EditIcon } from "../../res/icons";
import axios from "axios";

const UserProfile = () => {
  const [editMode, setEditMode] = useState(false);
  const userType = localStorage.getItem("uType");
  const userName = localStorage.getItem("username");
  const token = localStorage.getItem("jwtToken"); // Assuming you store the token in local storage

  // State for form values
  const [formValues, setFormValues] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "",
    postalCode: "",
    state: "",
    city: "",
    userId: "",
    parentId: "",
    commisurrtype: "",
    percentage: "",
    cityarea: "",
  });

  // State to manage loading and error states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch user details on component mount
  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://testapi.ipaisa.co.in/myapp/auth/getDetails",
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
            },
          }
        );

        const data = response.data;

        console.log(data);
        // Map the fetched data to form fields
        setFormValues({
          firstName: data.FirstName || "",
          middleName: data.MiddleName || "-",
          lastName: data.LastName || "",
          email: data.Email || "",
          phoneNumber: data.ContactNo || "",
          country: data.Country || "",
          postalCode: data.Pincode || "",
          state: data.State || "",
          city: data.City || "",
          userId: data.UserId || "",
          parentId: data.ParentId || "",
          commisurrtype: data.Com_sur_type || "",
          percentage: data.Percentage || "",
          cityarea: data.Address || "",
        });
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch user details");
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // Handle save and send PUT request to update details
  const handleSave = async () => {
    setEditMode(false);
    setLoading(true);
    try {
      const response = await axios.put(
        "https://testapi.ipaisa.co.in/myapp/auth/updateUser",
        {
          FirstName: formValues.firstName,
          MiddleName: formValues.middleName,
          LastName: formValues.lastName,
          Email: formValues.email,
          Country: formValues.country,
          Pincode: formValues.postalCode,
          State: formValues.state,
          City: formValues.city,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
          },
        }
      );
      console.log("Updated data:", response.data);
      setLoading(false);
      // Optionally display a success message or handle other UI updates
    } catch (err) {
      setError("Failed to update user details");
      setLoading(false);
    }
  };

  return (
    <Container
      style={{
        marginTop: "9%",
        marginBottom: "4%",
        borderRadius: "8px",
        padding: "2rem",
        marginTop: "calc(3% + 56px)",
      }}
    >
      <Paper elevation={2} style={{ padding: "2rem" }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
            My Profile
          </Typography>
          {editMode && (
            <Button
              style={{ backgroundColor: "#027BFE", color: "#ffffff" }}
              onClick={handleSave}
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}
            </Button>
          )}
        </Grid>

        {error && <Typography color="error">{error}</Typography>}

        {/* Profile Section */}
        <Grid
          container
          style={{
            border: "1px solid #D9D9D9",
            borderRadius: "8px",
            marginTop: "2%",
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid
              item
              xs={12}
              md={8}
              style={{ padding: "1rem", display: "flex", alignItems: "center" }}
            >
              <Avatar style={{ width: "70px", height: "70px" }} />
              <Box ml={2}>
                <Typography>
                  <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                    {userName}
                  </span>
                  <span style={{ color: "#818181" }}> ({userType})</span>
                </Typography>
                <Grid>
                  <Typography color="textSecondary">
                    User ID :{" "}
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      {formValues.userId}
                    </span>
                  </Typography>
                  <Typography color="textSecondary">
                    Parent ID :{" "}
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      {formValues.parentId}
                    </span>
                  </Typography>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: "1rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography color="textSecondary">
                    Surcharge/Commission
                  </Typography>
                  <Typography style={{ fontWeight: "bold", fontSize: "14px" }}>
                    {formValues.commisurrtype}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="textSecondary">Percentage</Typography>
                  <Typography style={{ fontWeight: "bold", fontSize: "14px" }}>
                    {formValues.percentage}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Personal Information Section */}
        <Grid
          container
          style={{
            border: "1px solid #D9D9D9",
            borderRadius: "8px",
            marginTop: "2%",
          }}
        >
          <Grid item xs={12} style={{ padding: "1rem" }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
                Personal Information
              </Typography>
              {/* <IconButton onClick={() => setEditMode(!editMode)} style={{ cursor: 'pointer' }}>
                <EditIcon />
              </IconButton> */}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">First Name</Typography>
                {editMode ? (
                  <TextField
                    name="firstName"
                    value={formValues.firstName}
                    onChange={handleInputChange}
                    fullWidth
                  />
                ) : (
                  <Typography style={{ fontWeight: "bold" }}>
                    {formValues.firstName}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Middle Name</Typography>
                {editMode ? (
                  <TextField
                    name="middleName"
                    value={formValues.middleName}
                    onChange={handleInputChange}
                    fullWidth
                  />
                ) : (
                  <Typography style={{ fontWeight: "bold" }}>
                    {formValues.middleName}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Last Name</Typography>
                {editMode ? (
                  <TextField
                    name="lastName"
                    value={formValues.lastName}
                    onChange={handleInputChange}
                    fullWidth
                  />
                ) : (
                  <Typography style={{ fontWeight: "bold" }}>
                    {formValues.lastName}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Email Address</Typography>
                {editMode ? (
                  <TextField
                    name="email"
                    value={formValues.email}
                    onChange={handleInputChange}
                    fullWidth
                  />
                ) : (
                  <Typography style={{ fontWeight: "bold" }}>
                    {formValues.email}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Phone Number</Typography>
                <Typography style={{ fontWeight: "bold" }}>
                  {formValues.phoneNumber}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Address Section */}
        <Grid
          container
          style={{
            border: "1px solid #D9D9D9",
            borderRadius: "8px",
            marginTop: "2%",
          }}
        >
          <Grid item xs={12} style={{ padding: "1rem" }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
                Address
              </Typography>
              {/* <IconButton onClick={() => setEditMode(!editMode)} style={{ cursor: 'pointer' }}>
                <EditIcon />
              </IconButton> */}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Postal Code</Typography>
                {editMode ? (
                  <TextField
                    name="postalCode"
                    value={formValues.postalCode}
                    onChange={handleInputChange}
                    fullWidth
                  />
                ) : (
                  <Typography style={{ fontWeight: "bold" }}>
                    {formValues.postalCode}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Area</Typography>
                {editMode ? (
                  <TextField
                    name="cityarea"
                    value={formValues.cityarea}
                    onChange={handleInputChange}
                    fullWidth
                  />
                ) : (
                  <Typography style={{ fontWeight: "bold" }}>
                    {formValues.cityarea}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">City</Typography>
                {editMode ? (
                  <TextField
                    name="city"
                    value={formValues.city}
                    onChange={handleInputChange}
                    fullWidth
                  />
                ) : (
                  <Typography style={{ fontWeight: "bold" }}>
                    {formValues.city}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">State</Typography>
                {editMode ? (
                  <TextField
                    name="state"
                    value={formValues.state}
                    onChange={handleInputChange}
                    fullWidth
                  />
                ) : (
                  <Typography style={{ fontWeight: "bold" }}>
                    {formValues.state}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Country</Typography>
                {editMode ? (
                  <TextField
                    name="country"
                    value={formValues.country}
                    onChange={handleInputChange}
                    fullWidth
                  />
                ) : (
                  <Typography style={{ fontWeight: "bold" }}>
                    {formValues.country}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default UserProfile;
