import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  Grid,
  Paper,
  CircularProgress,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const payoutModes = [
  { value: "NEFT", label: "NEFT" },
  { value: "RTGS", label: "RTGS" },
  { value: "IMPS", label: "IMPS" },
];

const PayoutSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  payeeaccountNumber: Yup.string().required("Payee Account Number is required"),
  bankIfsc: Yup.string().required("Bank IFSC is required"),
  transferMode: Yup.string().required("Transfer Mode is required"),
  transferAmount: Yup.number()
    .required("Transfer Amount is required")
    .positive("Amount must be positive"),
  remarks: Yup.string(),
});

const PayoutScreen = () => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [decryptedData, setDecryptedData] = useState(null);

  useEffect(() => {
    const path = window.location.pathname;
    const encryptedData = path.replace("/deeplinkpayout/", "");

    if (encryptedData) {
      decryptPayload(encryptedData);
    }
  }, []);

  const decryptPayload = async (encryptedData) => {
    try {
      const response = await axios.post(
        "https://testapi.ipaisa.co.in/myapp/ex/decryptKey",
        { data: encryptedData },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === "Success") {
        const decrypted = response.data.data;
        setDecryptedData(decrypted);
      } else {
        console.error("Failed to decrypt data:", response.data.message);
      }
    } catch (error) {
      console.error("Error decrypting data:", error);
    }
  };

  const getLocation = async () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => resolve(position.coords),
          (error) => reject(error)
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const location = await getLocation();
      const token = localStorage.getItem("jwtToken");
      const payeraccountNumber = "120664700000021";
      const payload = {
        ...values,
        payeraccountNumber,
        latitude: location.latitude.toFixed(4),
        longitude: location.longitude.toFixed(4),
      };

      await axios.post(
        "https://testapi.ipaisa.co.in/myapp/extrpout/pay",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Payout successful");
    } catch (error) {
      console.error("Payout failed:", error);
      toast.error("Payout failed");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box sx={{ p: 4, marginTop: "7%" }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
          Payout
        </Typography>
        <Formik
          initialValues={{
            name: decryptedData?.name || "",
            payeeaccountNumber: decryptedData?.payeeaccountNumber || "",
            bankIfsc: decryptedData?.bankIfsc || "",
            transferMode: decryptedData?.transferMode || "",
            transferAmount: decryptedData?.transferAmount || "",
            remarks: decryptedData?.remarks || "3rd party payout",
            purpose: "REIMBURSEMENT",
          }}
          validationSchema={PayoutSchema}
          enableReinitialize={true} // This will reinitialize the form with new values when decryptedData changes
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    name="name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    name="payeeaccountNumber"
                    label="Payee Account Number"
                    variant="outlined"
                    fullWidth
                    error={
                      touched.payeeaccountNumber &&
                      Boolean(errors.payeeaccountNumber)
                    }
                    helperText={
                      touched.payeeaccountNumber && errors.payeeaccountNumber
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    name="bankIfsc"
                    label="Bank IFSC"
                    variant="outlined"
                    fullWidth
                    error={touched.bankIfsc && Boolean(errors.bankIfsc)}
                    helperText={touched.bankIfsc && errors.bankIfsc}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    name="transferMode"
                    label="Transfer Mode"
                    select
                    variant="outlined"
                    fullWidth
                    error={touched.transferMode && Boolean(errors.transferMode)}
                    helperText={touched.transferMode && errors.transferMode}
                  >
                    {payoutModes.map((mode) => (
                      <MenuItem key={mode.value} value={mode.value}>
                        {mode.label}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    name="transferAmount"
                    label="Transfer Amount"
                    variant="outlined"
                    type="number"
                    fullWidth
                    error={
                      touched.transferAmount && Boolean(errors.transferAmount)
                    }
                    helperText={touched.transferAmount && errors.transferAmount}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    name="remarks"
                    label="Remarks"
                    variant="outlined"
                    fullWidth
                    error={touched.remarks && Boolean(errors.remarks)}
                    helperText={touched.remarks && errors.remarks}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 5 }}
                gap={2}
              >
                <Button
                  style={{
                    backgroundColor: "#FF0000",
                    color: "#ffffff",
                    textTransform: "none",
                    width: "50%",
                    borderRadius: "9px",
                    fontSize: "20px",
                    height: "48px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  style={{
                    backgroundColor: "#027BFE",
                    color: "white",
                    textTransform: "none",
                    width: "50%",
                    borderRadius: "9px",
                    fontSize: "20px",
                    height: "48px",
                  }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} style={{ color: "white" }} />
                  ) : (
                    "Pay"
                  )}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default PayoutScreen;
