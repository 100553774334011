import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { getBankDetails, addBeneficiary, verifyBankDetails } from "./api";
import { PartnerArrowIcon } from "../../res/NewWebIcons";
import Failedtoverify from "./Failedtoverify";
import Successtoverify from "./Successtoverify";
import AddBeneSuccessPop from "./AddBeneSuccessPop";
import Failedtoaddbene from "./Failedtoaddbene";
import AccPresentPopup from "./AccPresentPopup";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { initiatePayout } from "../../redux/actions/payoutActions";
import PayoutPopup from "./PayoutPopup";
import PayoutFailedPopup from "./PayoutFailedPopup";
import InsufficiantB from "./InsufficiantB";
import DeleteBeneficiarySucc from "./DeleteBeneficiarySucc";
import DeleteBeneficiaryFail from "./DeleteBeneficiaryFail";
import { Delete } from "@mui/icons-material";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: "#D0EFFF",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
}));

const NewAddBeneficiary = () => {
  const classes = useStyles();
  const [bankDetails, setBankDetails] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [verifyResult, setVerifyResult] = useState(null);
  const [failedtoverify, setFailedtoverify] = useState(false);
  const [successtoverify, setSuccesstoverify] = useState(false);
  const [addbenesuccess, setAddbenesuccess] = useState(false);
  const [failedtoaddbene, setFailedtoaddbene] = useState(false);
  const [accPresentPopup, setAccPresentPopup] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [verificationSuccess, setVerificationSuccess] = useState(false); // New state for verification success
  const [loadingPay, setLoadingPay] = useState(false);
  const [showSuccessPayout, setShowSuccessPayout] = useState(false);
  const [showFailedPayout, setShowFailedPayout] = useState(false);
  const [insufficiantB, setInsufficiantB] = useState(false);
  const [deleteBeneSuccess, setDeleteBeneSuccess] = useState(false);
  const [deleteBeneFail, setDeleteBeneFail] = useState(false);

  const handleClose = () => {
    setFailedtoverify(false);
    setSuccesstoverify(false);
    setAddbenesuccess(false);
    setFailedtoaddbene(false);
    setAccPresentPopup(false);
    setShowSuccessPayout(false);
    setShowFailedPayout(false);
    setInsufficiantB(false);
    setDeleteBeneSuccess(false);
    setDeleteBeneFail(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    getBankDetails(setBankDetails, setStatusMessage);
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    bankName: Yup.string().required("Bank Name is required"),
    branch: Yup.string().required("Branch is required"),
    ifsc: Yup.string().matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code"),
    accountNumber: Yup.string()
      .matches(/^[0-9]+$/, "Account Number must contain only digits")
      .required("Account Number is required"),
  });

  const initialValues = {
    name: "",
    bankName: "",
    branch: "",
    ifsc: "",
    accountNumber: "",
  };

  const onSubmit = (values, { setSubmitting }) => {
    addBeneficiary(
      values,
      setSubmitting,
      () => getBankDetails(setBankDetails, setStatusMessage),
      setAddbenesuccess,
      setFailedtoaddbene,
      setAccPresentPopup
    );
  };

  const handleCancel = () => {
    // navigate("/navbar");
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDeleteBeneficiary = async (accountNo) => {
    try {
      const token = localStorage.getItem("jwtToken"); // Ensure you have the JWT token stored in local storage
      const response = await axios.delete(
        "https://testapi.ipaisa.co.in/myapp/auth/deleteBeneficiary",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: {
            accountNo: accountNo,
          },
        }
      );

      // Check if the response indicates success
      if (response.status === 200 && response.data.statusCodeValue === 200) {
        setDeleteBeneSuccess(true);
        toast.success(response.data.body.message); // Display success message
        // Optionally, refresh the bank details or update the UI accordingly
        getBankDetails(setBankDetails, setStatusMessage); // Refresh the list if necessary
      }
    } catch (error) {
      console.error(
        "Error deleting beneficiary:",
        error.response ? error.response.data : error.message
      );
      setDeleteBeneFail(true);
      toast.error(
        "Error deleting beneficiary: " +
          (error.response ? error.response.data.body.message : error.message)
      );
    }
  };

  const handleVerifyBank = async (accountNumber, ifsc) => {
    // Check if account number already exists in bankDetails
    const accountExists = bankDetails.some(
      (bank) => bank.accountNumber === accountNumber
    );

    // if (accountExists) {
    //   toast.error("Account Number already exists in the table");
    //   return; // Exit the function early
    // }

    setLoadingVerify(true);
    try {
      const result = await verifyBankDetails(accountNumber, ifsc);
      setVerifyResult(result.status);

      if (result.status === "Transaction Successful") {
        toast.success("Account Verification Successful");
        setSuccesstoverify(true);
        setVerificationSuccess(true); // Set verification success to true
      } else {
        toast.error("Transaction Failed: " + result.status);
        setFailedtoverify(true);
        setVerificationSuccess(false); // Reset verification success on failure
      }
    } catch (error) {
      toast.error("Failed to verify bank details");
      setFailedtoverify(true);
      setVerificationSuccess(false); // Reset on error
    } finally {
      setLoadingVerify(false); // Stop loading spinner
    }
  };
  const dispatch = useDispatch();

  const handlePay = async (values) => {
    setLoadingPay(true); // Start loading
    const payload = {
      name: values.name,
      payeeaccountNumber: values.accountNumber,
      bankIfsc: values.ifsc,
      transferMode: "IMPS",
      transferAmount: values.amount,
      remarks: "testing",
      purpose: "REIMBURSEMENT",
      payeraccountNumber: "120664700000021", // Replace with actual payer account number
    };

    try {
      await dispatch(
        initiatePayout(
          payload,
          setShowSuccessPayout,
          setInsufficiantB,
          setShowFailedPayout
        )
      );
    } catch (error) {
      console.log("Error during payout:", error);
    } finally {
      setLoadingPay(false); // Stop loading
    }
  };

  useEffect(() => {
    if (showSuccessPayout) {
      // Trigger success popup
      toast.success("Payout was successful!"); // This will show a success toast notification
    }
    if (insufficiantB) {
      // Trigger insufficient balance popup
      toast.error("Insufficient balance for the payout."); // This will show an error for insufficient balance
    }
    if (showFailedPayout) {
      // Trigger failed payout popup
      toast.error("Failed Payout"); // This will show a generic error message
    }
  }, [showSuccessPayout, insufficiantB, showFailedPayout]);

  const filteredBankDetails = bankDetails.filter(
    (bank) =>
      (bank.name &&
        bank.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (bank.user.userid &&
        bank.user.userid.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (bank.user.mobileNumber &&
        bank.user.mobileNumber.includes(searchQuery)) ||
      (bank.bankName &&
        bank.bankName.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (bank.branch &&
        bank.branch.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (bank.ifsc &&
        bank.ifsc.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (bank.accountNumber &&
        bank.accountNumber.toString().includes(searchQuery))
  );

  const lineHeight = "56px";
  const lineHeight2 = "50px";

  const renderForm = (values, handleChange, handleBlur, isSubmitting) => (
    <Paper style={{ padding: "2rem", borderRadius: "8px" }}>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="name"
              name="name"
              label="Name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                <ErrorMessage
                  name="name"
                  component="div"
                  style={{ color: "red" }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="bankName"
              name="bankName"
              label="Bank Name"
              value={values.bankName}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                <ErrorMessage
                  name="bankName"
                  component="div"
                  style={{ color: "red" }}
                />
              }
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{ marginTop: { xs: "0%", md: "1.5%" } }}
          >
            <TextField
              fullWidth
              variant="outlined"
              id="branch"
              name="branch"
              label="Branch"
              value={values.branch}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                <ErrorMessage
                  name="branch"
                  component="div"
                  style={{ color: "red" }}
                />
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ marginTop: { xs: "0%", md: "1.5%" } }}
          >
            <TextField
              fullWidth
              variant="outlined"
              id="ifsc"
              name="ifsc"
              label="IFSC"
              value={values.ifsc}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                <ErrorMessage
                  name="ifsc"
                  component="div"
                  style={{ color: "red" }}
                />
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ marginTop: { xs: "0%", md: "1.5%" } }}
          >
            <TextField
              fullWidth
              variant="outlined"
              id="accountNumber"
              name="accountNumber"
              label="Account Number"
              value={values.accountNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                <ErrorMessage
                  name="accountNumber"
                  component="div"
                  style={{ color: "red" }}
                />
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ marginTop: { xs: "0%", md: "1.5%" } }}
          >
            <Button
              sx={{
                border: "1.5px solid #0288D1",
                color: "#0288D1",
                fontWeight: "bold",
                textTransform: "none",
                fontSize: "16px",
                width: { xs: "100%", md: "25%" },
                height: lineHeight,
                borderRadius: "9px",
              }}
              onClick={() =>
                handleVerifyBank(values.accountNumber, values.ifsc)
              }
              disabled={loadingVerify} // Disable button when loading
            >
              {loadingVerify ? <CircularProgress size={24} /> : "Verify Bank"}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: "flex", alignItems: "center" }}
          >
            <TextField
              fullWidth
              variant="outlined"
              id="amount"
              name="amount"
              label="Amount"
              type="number" // Ensures only numeric input
              value={values.amount}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                <ErrorMessage
                  name="amount"
                  component="div"
                  style={{ color: "red" }}
                />
              }
            />
            <Button
              sx={{
                backgroundColor: "#027BFE",
                color: "white",
                fontSize: "16px",
                height: "56px", // Match height with TextField
                textTransform: "none",
                borderRadius: "9px",
                marginLeft: "1rem", // Add some space between the TextField and button
              }}
              onClick={() => handlePay(values)}
              disabled={!verificationSuccess || loadingPay}
            >
              {loadingPay ? (
                <CircularProgress sx={{ color: "white" }} size={24} />
              ) : (
                "Pay"
              )}
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: { xs: "0%", md: "1%" } }}>
            <Box display="flex" justifyContent="end" gap={2}>
              <Button
                sx={{
                  border: "1.5px solid #FF0000",
                  color: "#FF0000",
                  textTransform: "none",
                  fontSize: "16px",
                  height: lineHeight2,
                  width: { xs: "100%", md: "16%" },
                  borderRadius: "9px",
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{
                  backgroundColor: "#027BFE",
                  color: "white",
                  fontSize: "16px",
                  height: lineHeight2,
                  textTransform: "none",
                  width: { xs: "100%", md: "16%" },
                  "&:hover": {
                    backgroundColor: "#027BFE",
                    color: "#FFFFFF",
                  },
                  borderRadius: "9px",
                }}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Adding..." : "Add"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </Paper>
  );

  return (
    <Container style={{ padding: "2rem", marginTop: "calc(3% + 56px)" }}>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1%",
          justifyContent: "start",
        }}
      >
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "14px" }}
        >
          Balance
        </Typography>
        <PartnerArrowIcon />
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "14px" }}
        >
          Add Beneficiary
        </Typography>
      </Grid>
      <Grid style={{ marginTop: "1%" }}>
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "sans-serif",
            fontSize: "19px",
            color: "#343C6A",
          }}
        >
          Add Beneficiary
        </Typography>
      </Grid>

      <Grid spacing={2} style={{ marginTop: "3%" }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleBlur, isSubmitting }) =>
            renderForm(values, handleChange, handleBlur, isSubmitting)
          }
        </Formik>

        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "3%",
          }}
        >
          <Grid>
            <Typography
              style={{
                fontWeight: "bold",
                fontFamily: "sans-serif",
                fontSize: "19px",
                color: "#343C6A",
              }}
            >
              History
            </Typography>
          </Grid>

          <Grid
            style={{
              display: "flex",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              maxWidth: "60%",
              borderRadius: 10,
              border: "1px solid rgba(0, 0, 0, 0.3)",
            }}
          >
            <IconButton>
              <SearchIcon style={{ color: "rgba(0, 0, 0, 0.5)" }} />
            </IconButton>
            <InputBase
              style={{ color: "rgba(0, 0, 0, 3)" }}
              placeholder="Search"
              sx={{ width: "100%" }}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Grid>
        </Grid>

        <Grid style={{ marginTop: "2%" }}>
          <TableContainer
            component={Paper}
            sx={{
              maxWidth: "100%",
              overflowX: "auto",
              borderRadius: "12px 12px 0px 0px",
            }}
          >
            <Table>
              <TableHead style={{ backgroundColor: "#027BFE" }}>
                <TableRow>
                  <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                    Sr.No
                  </TableCell>
                  <TableCell sx={{ color: "#FFFFFF", textAlign: "start" }}>
                    Name
                  </TableCell>
                  <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                    User ID
                  </TableCell>
                  <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                    Mobile Number
                  </TableCell>
                  <TableCell sx={{ color: "#FFFFFF", textAlign: "start" }}>
                    Bank Name
                  </TableCell>
                  <TableCell sx={{ color: "#FFFFFF", textAlign: "start" }}>
                    Branch
                  </TableCell>
                  <TableCell sx={{ color: "#FFFFFF", textAlign: "start" }}>
                    IFSC
                  </TableCell>
                  <TableCell sx={{ color: "#FFFFFF", textAlign: "start" }}>
                    Account Number
                  </TableCell>
                  <TableCell sx={{ color: "#FFFFFF", textAlign: "start" }}>
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredBankDetails.length > 0 ? (
                  filteredBankDetails.map((bank, index, row) => (
                    <TableRow
                      key={bank.id}
                      className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                    >
                      <TableCell sx={{ textAlign: "center" }}>
                        {index + 1}
                      </TableCell>
                      <TableCell sx={{ textAlign: "start" }}>
                        {bank.name}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {bank.user.userid}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {bank.user.mobileNumber}
                      </TableCell>
                      <TableCell sx={{ textAlign: "start" }}>
                        {bank.bankName}
                      </TableCell>
                      <TableCell sx={{ textAlign: "start" }}>
                        {bank.branch}
                      </TableCell>
                      <TableCell sx={{ textAlign: "start" }}>
                        {bank.ifsc}
                      </TableCell>
                      <TableCell sx={{ textAlign: "start" }}>
                        {bank.accountNumber}
                      </TableCell>
                      <TableCell sx={{ textAlign: "start" }}>
                        <IconButton
                          onClick={() =>
                            handleDeleteBeneficiary(bank.accountNumber)
                          }
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>{" "}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      {statusMessage && (
                        <Typography>{statusMessage}</Typography>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {successtoverify && <Successtoverify handleClose={handleClose} />}
      {failedtoverify && <Failedtoverify handleClose={handleClose} />}
      {addbenesuccess && <AddBeneSuccessPop handleClose={handleClose} />}
      {failedtoaddbene && <Failedtoaddbene handleClose={handleClose} />}
      {accPresentPopup && <AccPresentPopup handleClose={handleClose} />}
      {showSuccessPayout && <PayoutPopup handleClose={handleClose} />}
      {showFailedPayout && <PayoutFailedPopup handleClose={handleClose} />}
      {insufficiantB && <InsufficiantB handleClose={handleClose} />}
      {deleteBeneSuccess && <DeleteBeneficiarySucc handleClose={handleClose} />}
      {deleteBeneFail && <DeleteBeneficiaryFail handleClose={handleClose} />}
    </Container>
  );
};

export default NewAddBeneficiary;
