import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for styling
import logo from "../Assets/login/iPaisaLogo.jpg";

const SetPassword = ({ open, onClose }) => {
  const [newPassword, setNewPassword] = useState(["", "", "", ""]);
  const [confirmPassword, setConfirmPassword] = useState(["", "", "", ""]);
  const [passwordError, setPasswordError] = useState("");
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const newPassRefs = useRef([]);
  const confirmPassRefs = useRef([]);

  const handleSetPassword = async () => {
    if (newPassword.join("") !== confirmPassword.join("")) {
      setPasswordError("IPIN must match");
      return;
    }

    setPasswordError("");
    const mpin = newPassword.join(""); // Combine the new password array into a string
    const token = localStorage.getItem("jwtToken"); // Get the JWT token from localStorage

    try {
      const response = await axios.post(
        "https://testapi.ipaisa.co.in/myapp/auth/setTxnMpin",
        { mpin },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        }
      );

      if (response.data && response.data.body) {
        const message = response.data.body.message;

        // Check if the message is "Mpin already Set"
        if (message === "Mpin alredy Set") {
          // Set `isTxnMpinSet` to true in localStorage
          localStorage.setItem("isTxnMpinSet", "true");

          // Show success toast for already set MPIN
          toast.info("Mpin already set.", {
            position: "top-right",
            autoClose: 3000, // auto close after 3 seconds
          });

          // Close the modal after 3 seconds
          setTimeout(() => {
            onClose();
          }, 3000);
        } else {
          // Show success toast for successful MPIN set
          toast.success(message, {
            position: "top-right",
            autoClose: 3000, // auto close after 3 seconds
          });

          // Clear the form after successful submission
          setNewPassword(["", "", "", ""]);
          setConfirmPassword(["", "", "", ""]);
          setPasswordError("");
          setApiError("");

          // Set `isTxnMpinSet` to true in localStorage when successful
          localStorage.setItem("isTxnMpinSet", "True");

          // Close the modal after 3 seconds
          setTimeout(() => {
            onClose();
          }, 3000);
        }
      }
    } catch (error) {
      setApiError("Failed to set IPIN. Please try again.");
      console.error("API Error:", error);

      // Show error toast
      toast.error("Failed to set IPIN. Please try again.", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const handleNewPasswordChange = (value, index) => {
    if (/^\d$/.test(value)) {
      const updatedPassword = [...newPassword];
      updatedPassword[index] = value;
      setNewPassword(updatedPassword);
      if (index < 3) {
        newPassRefs.current[index + 1].focus();
      }
    }
  };

  const handleConfirmPasswordChange = (value, index) => {
    if (/^\d$/.test(value)) {
      const updatedPassword = [...confirmPassword];
      updatedPassword[index] = value;
      setConfirmPassword(updatedPassword);
      if (index < 3) {
        confirmPassRefs.current[index + 1].focus();
      }
    }
  };

  const handleBackspace = (index, passwordType) => {
    if (passwordType === "new") {
      const updatedPassword = [...newPassword];
      if (updatedPassword[index] !== "") {
        updatedPassword[index] = "";
        setNewPassword(updatedPassword);
        newPassRefs.current[index].focus();
      } else if (index > 0) {
        newPassRefs.current[index - 1].focus();
      }
    } else {
      const updatedPassword = [...confirmPassword];
      if (updatedPassword[index] !== "") {
        updatedPassword[index] = "";
        setConfirmPassword(updatedPassword);
        confirmPassRefs.current[index].focus();
      } else if (index > 0) {
        confirmPassRefs.current[index - 1].focus();
      }
    }
  };

  const renderPasswordFields = (
    password,
    handleChange,
    handleBackspace,
    refs,
    passwordType
  ) => {
    return (
      <Grid container spacing={2}>
        {password.map((char, index) => (
          <Grid item key={index}>
            <TextField
              inputProps={{
                maxLength: 1,
                sx: {
                  width: "48px",
                  height: "48px",
                  textAlign: "center",
                  borderRadius: "6px 6px 6px 6px",
                  border: "1px solid #ccc",
                },
              }}
              value={char}
              onChange={(e) => handleChange(e.target.value, index)}
              onKeyDown={(e) => {
                if (e.key === "Backspace") {
                  handleBackspace(index, passwordType);
                }
              }}
              inputRef={(el) => (refs.current[index] = el)}
              type="password"
              variant="outlined"
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const handleCancel = () => {
    setNewPassword(["", "", "", ""]);
    setConfirmPassword(["", "", "", ""]);
    setPasswordError("");
    setApiError("");
    onClose(); // Close the modal on cancel
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ color: "#027BFE", fontWeight: "bold" }}>
          Set Your IPIN!
        </Typography>
        <img
          src={logo}
          alt="iPaisa Logo"
          style={{
            height: 58,
            width: 58,
            backgroundColor: "transparent",
            borderRadius: "50%",
          }}
        />
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Welcome! Please Create A Strong IPIN For Your Account.
        </Typography>

        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1">New IPIN</Typography>
          {renderPasswordFields(
            newPassword,
            handleNewPasswordChange,
            handleBackspace,
            newPassRefs,
            "new"
          )}
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1">Confirm New IPIN</Typography>
          {renderPasswordFields(
            confirmPassword,
            handleConfirmPasswordChange,
            handleBackspace,
            confirmPassRefs,
            "confirm"
          )}
          {passwordError && (
            <Typography variant="caption" color="error" sx={{ mt: 1 }}>
              {passwordError}
            </Typography>
          )}
          {apiError && (
            <Typography variant="caption" color="error" sx={{ mt: 1 }}>
              {apiError}
            </Typography>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleCancel}
          disabled={loading} // Disable button when loading
          sx={{
            textTransform: "none",
            width: "18%",
            height: "50px",
            borderRadius: "8px",
            color: "#808080",
            border: "1px solid #D9D9D9",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSetPassword}
          disabled={loading} // Disable button when loading
          sx={{
            backgroundColor: "#027BFE",
            color: "white",
            textTransform: "none",
            width: "18%",
            borderRadius: "8px",
            height: "50px",
            ":hover": {
              backgroundColor: "#0056b3",
            },
          }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Set IPIN"
          )}
        </Button>
      </DialogActions>

      {/* Toast notification container */}
      <ToastContainer />
    </Dialog>
  );
};

export default SetPassword;
