import {
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { FilterAlt } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: "#D0EFFF",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
}));

const HierarchyTrans = () => {
  const [trnsList, setTranslist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [pageFrom, setPageFrom] = useState(0); // Total pages
  const [pageTo, setPageTo] = useState(0); // Total pages
  const [pageSize, setPageSize] = useState(50);
  const [hasNext, setHasNext] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState(null); // For filter icon menu
  const [filters, setFilters] = useState({
    id: "",
    startDate: dayjs().format("YYYY-MM-DD"), // Default to 30 days ago
    endDate: dayjs().format("YYYY-MM-DD"), // Default to today
    utr: "",
    virtualId: "",
  }); // Filter states
  var totalAmount = 0;
  const classes = useStyles();
  const navigate = useNavigate();

  const statusColors = {
    received: "#67C529",
    pending: "#E8DC22",
    uncaptured: "#E8DC22",
    "timed out": "#E29D95",
    failure: "#FF0000",
    unsettled: "#E8DC22",
    "partially refunded": "#3D8AC5",
    refunded: "#655AA1",
  };

  // Handle filter menu open/close
  const handleFilterMenuClick = (event) => {
    setFilterMenuAnchor(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchList(newPage);
  };
  // Separate function to handle DatePicker change
  const handleDateChange = (key, newValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: newValue ? dayjs(newValue).format("YYYY-MM-DD") : "",
    }));
  };

  // Function to handle filter changes for text inputs
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  // Apply filters and call the fetchList function with page 1
  const applyFilters = () => {
    handleFilterMenuClose();
    if (
      filters.endDate &&
      filters.startDate &&
      dayjs(filters.endDate).isBefore(dayjs(filters.startDate))
    ) {
      // toast.error("End Date cannot be less than Start Date.");
      Swal.fire({
        title: "Something Went Wrong..!!",
        text: "End Date cannot be less than Start Date.",
        icon: "error",
      });
      return;
    } else {
      // setErrorMessage(""); // Clear error if validation passes
      fetchList(1); // Trigger fetch with the current filters
    }
    // console.log("Filters applied:", filters); // Debugging
  };

  const fetchList = async (page) => {
    const jwtToken = localStorage.getItem("jwtToken");
    // console.log(filteredTransactions + " this is filter");

    // Prepare the params based on the filters state
    const params = {
      created_at: ``,
      created_at_start: filters.startDate
        ? dayjs(filters.startDate).format("YYYY-MM-DD")
        : ``,
      created_at_end: filters.endDate
        ? dayjs(filters.endDate).format("YYYY-MM-DD")
        : ``,
      current: page,
      order_id: ``,
      pageSize: pageSize,
      status: filters.status || ``,
      unique_transaction_reference: filters.utr || ``,
      virtual_account_id: filters.virtualId || ``,
    };

    try {
      const response = await axios.get(
        "https://testapi.ipaisa.co.in/myapp/virtual/hierarchyreport",
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
          params: params, // Use the dynamic params here
        }
      );
      console.log(response);
      const resultsArr = response.data;
      const settingHasNext = response.data.data.pagination.hasNext;
      const settingPageFrom = response.data.data.pagination.showing.from;
      const settingPageTo = response.data.data.pagination.showing.to;
      console.log(resultsArr);
      setTranslist(resultsArr);
      setHasNext(settingHasNext);
      setPageFrom(settingPageFrom);
      setPageTo(settingPageTo);
      // console.log(resultsArr);
    } catch (error) {
      console.error("Error fetching PayIn data:", error);
    }
  };
  const handleNavigation = () => {
    navigate("/virtualtrans"); // Specify your target route here
  };

  //   const Dropdown = ({ users, onUserSelect }) => {
  //     const [selectedUser, setSelectedUser] = useState('');

  //     const handleChange = (event) => {
  //       const userId = event.target.value;
  //       setSelectedUser(userId);
  //       onUserSelect(userId); // Call parent function to notify about selected user
  //     };

  useEffect(() => {
    fetchList(currentPage);
  }, []);

  return (
    <>
      <Container
        style={{
          marginBottom: "4%",
          borderRadius: "8px",
          marginTop: "calc(3% + 56px)",
          backgroundColor: "#background: #F5F7FA",
        }}
      >
        <h2>This is Hierarchy wise Transactions List </h2>

        {/* Filter Button with Icon */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {/* <Select
            labelId="user-select-label"
            id="user-select"
            value=""
            label="Select User"
            onChange={handleChange}
          >
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </Select> */}
          <IconButton onClick={handleFilterMenuClick}>
            <FilterAlt />
          </IconButton>
          <Typography
            onClick={handleNavigation}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Back
          </Typography>

          {/* Filter Menu */}
          <Menu
            anchorEl={filterMenuAnchor}
            open={Boolean(filterMenuAnchor)}
            onClose={handleFilterMenuClose}
            disableAutoFocus
            disableEnforceFocus
          >
            <MenuItem>
              <TextField
                label="Transaction ID"
                name="id"
                value={filters.id}
                onChange={handleFilterChange}
                variant="outlined"
                onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                fullWidth
                autoFocus
              />
            </MenuItem>

            <MenuItem>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={filters.startDate ? dayjs(filters.startDate) : null}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                  onChange={(newValue) =>
                    handleDateChange("startDate", newValue)
                  }
                />
              </LocalizationProvider>
            </MenuItem>

            <MenuItem>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  value={filters.endDate ? dayjs(filters.endDate) : null}
                  onChange={(newValue) => handleDateChange("endDate", newValue)}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                />
              </LocalizationProvider>
            </MenuItem>

            <MenuItem>
              <TextField
                label="UTR Number"
                name="utr"
                value={filters.utr}
                onChange={handleFilterChange}
                variant="outlined"
                onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                fullWidth
              />
            </MenuItem>

            <MenuItem>
              <TextField
                label="Virtual Account ID"
                name="virtualId"
                value={filters.virtualId}
                onChange={handleFilterChange}
                variant="outlined"
                onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                fullWidth
              />
            </MenuItem>

            <MenuItem>
              <Button variant="contained" onClick={applyFilters}>
                Apply Filters
              </Button>
            </MenuItem>
          </Menu>
        </Box>

        {/* Table Container Started...!!! */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead
              style={{
                backgroundColor: "#027BFE",
                color: "white",
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Sr. No.
                </TableCell>
                {/* <TableCell style={{ color: 'white' }} align="center">Type</TableCell> */}
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Txn ID
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Status
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Payment Mode
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Amount
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  UTR Number
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Transaction Date
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Full Name
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Phone No.
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  UPI ID/Handle
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Account No.
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  IFSC No.
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Label
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Virtual UPI Handle
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trnsList && trnsList.length > 0 ? (
                trnsList.map((txn, i) => {
                  if (txn.status === "failure") {
                  } else {
                    totalAmount = totalAmount + txn.amount;
                  }
                  return (
                    <TableRow
                      key={txn.id} // Use unique key based on the transaction ID
                      className={`${classes.tableRow} ${i % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                    >
                      <TableCell>{i + 1}</TableCell> {/* Serial Number */}
                      <TableCell>{txn.id}</TableCell> {/* Transaction ID */}
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          {/* Colored dot */}
                          <Box
                            sx={{
                              height: 10,
                              width: 10,
                              borderRadius: "50%",
                              backgroundColor:
                                statusColors[txn.status] || "black", // Default color if status is not found
                              marginRight: 1, // Space between dot and status text
                            }}
                          />
                          {/* <Box
                            sx={{
                              height: 10,
                              width: 10,
                              borderRadius: "50%",
                              backgroundColor:
                                txn.status === "received" ? "green" : "red", // Set color based on status
                              marginRight: 1, // Space between dot and status text
                            }}
                          /> */}
                          {/* Status text */}
                          <Typography>{txn.status}</Typography>
                        </Box>
                      </TableCell>
                      {/* Transaction Status */}
                      <TableCell>{txn.payment_mode}</TableCell>
                      {/* Payment Mode */}
                      <TableCell>{txn.amount}</TableCell>
                      {/* {(totalAmount = totalAmount + txn.amount)} */}
                      {/* Transaction Amount */}
                      <TableCell>{txn.unique_transaction_reference}</TableCell>
                      {/* UTR */}
                      {/* Remitter Name */}
                      <TableCell>
                        {dayjs(txn.transaction_date).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      </TableCell>
                      {/* {"Transaction Date"} */}
                      {/* Remitter Phone */}
                      <TableCell>{txn.remitter_full_name}</TableCell>
                      {/* {"Remitter Name "} */}
                      {/* UPI Handle */}
                      <TableCell>{txn.remitter_phone_number}</TableCell>
                      {/* {"Remitter Phone Number "} */}
                      {/* Creation Date */}
                      <TableCell>{txn.remitter_upi_handle}</TableCell>
                      {/* {"Remitter UPI "} */}
                      {/* Settlement Date */}
                      <TableCell>{txn.remitter_account_number}</TableCell>
                      {/* {"Account Number "} */}
                      {/* Bank Settlement Date */}
                      <TableCell>{txn.remitter_account_ifsc}</TableCell>
                      {/* {"IFSC "} */}
                      {/* Virtual Account */}
                      <TableCell>{txn.virtual_account.label}</TableCell>
                      {/* {"Virtual Label "} */}
                      <TableCell>
                        {txn.virtual_account.virtual_upi_handle}
                      </TableCell>
                      {/* {"Virtual UPI "} */}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    No transactions available
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell colSpan={9} align="center" sx={{ width: "100%" }}>
                  <Typography>Total Amount = Rs. {totalAmount}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <Button
                    variant="outlined"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage <= 1}
                    size="small"
                    sx={{ marginRight: 1 }}
                  >
                    Back
                  </Button>
                  <span>
                    Showing Transactions From {pageFrom} To {pageTo}
                  </span>
                  <Button
                    variant="outlined"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={!hasNext}
                    size="small"
                    sx={{ marginLeft: 1 }}
                  >
                    Next
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default HierarchyTrans;
