import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";

const ReverseMoney = () => {
  const [formData, setFormData] = useState({
    receiverId: "",
    receiverName: "",
    receiverType: "",
    amount: "",
    walletBalance: "", // Example wallet balance
    previousBalance: "4500", // Example previous balance
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://testapi.ipaisa.co.in/myapp/admin/reversebalance",
        formData
      );
      console.log("Response:", response.data);
      // Add your logic to handle successful response (e.g., show success message)
    } catch (error) {
      console.error("There was an error reversing the balance!", error);
      // Add your logic to handle errors (e.g., show error message)
    }
  };

  return (
    <Container maxWidth="xm" style={{ marginTop: "5%", padding: "60px" }}>
      <Box
        sx={{
          backgroundColor: "white",
          padding: 3,
          borderRadius: 2,
          boxShadow: 1,
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          style={{ fontWeight: "bold", paddingBottom: "1%" }}
        >
          Reverse Money
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Receiver ID"
                name="receiverId"
                value={formData.receiverId}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Receiver Name / Business Name"
                name="receiverName"
                value={formData.receiverName}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Receiver Type</InputLabel>
                <Select
                  name="receiverType"
                  value={formData.receiverType}
                  onChange={handleInputChange}
                  label="Receiver Type"
                >
                  <MenuItem value="Channel Partner">Channel Partner</MenuItem>
                  <MenuItem value="Super Distributor">
                    Super Distributor
                  </MenuItem>
                  <MenuItem value="Master Distributor">
                    Master Distributor
                  </MenuItem>
                  <MenuItem value="Area Distributor">Area Distributor</MenuItem>
                  <MenuItem value="Retailer">Retailer</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Amount"
                name="amount"
                type="string"
                value={formData.amount}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <TextField
                label="Wallet Balance"
                value={formData.walletBalance}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Previous Balance"
                value={formData.previousBalance}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                fullWidth
                style={{
                  padding: "10px 0",
                  textTransform: "none",
                  color: "white",
                  backgroundColor: "#027BFE",
                  borderRadius: "7px",
                  height: "120%",
                }}
              >
                Reverse Money
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default ReverseMoney;
