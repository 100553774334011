import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { makeStyles } from "@mui/styles";
import { BrowserView, MobileView } from "react-device-detect";
import iPaisaLogo from "../Assets/iPaisaLogo/iPaisaLogo.jpg"; // Adjust this path if needed

const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: "#C1E0FB",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
  PayInText: {
    color: "green",
  },
  PayOutText: {
    color: "red",
  },
}));

const PayInAndOut = ({ payInData, payOutData }) => {
  const classes = useStyles();
  const [transactionData, setTransactionData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchTransactionData = async () => {
    const jwtToken = localStorage.getItem("jwtToken");

    try {
      const [payInResponse, payOutResponse] = await Promise.all([
        axios.get("https://testapi.ipaisa.co.in/myapp/auth/easTxnDetails", {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }),
        axios.get("https://testapi.ipaisa.co.in/myapp/auth/pOuttTxnDetails", {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }),
      ]);

      const payInArray = payInResponse.data.body || [];
      const payOutArray = payOutResponse.data.body || [];

      const payInData =
        payInArray.length > 0
          ? payInArray.map((txn) => ({
              id: txn.id,
              txnId: txn.txnid,
              amount: txn.amount,
              status: txn.status,
              addedOn: txn.addedon,
              type: "Pay-In",
              senderName: txn.payeeName,
              senderNumber: txn.payeeAccount,
              remark: txn.externalRef,
            }))
          : [];

      const payOutData =
        payOutArray.length > 0
          ? payOutArray.map((txn) => ({
              id: txn.id,
              txnId: txn.orderid,
              utrNo: txn.txnReferenceId,
              amount: txn.txnValue,
              status: txn.status,
              addedOn: txn.timestamp,
              type: "Pay-Out",
              senderName: txn.payerName,
              reciverName: txn.payeeName,
              senderNumber: txn.payerAccount,
              accountName: txn.payeeName,
              accountNumber: txn.payeeAccount,
              surcharge: txn.poolAmount,
              remark: txn.orderid,
              walletOpeningBalance: txn.walletOpeningBalance,
              walletClosingBalance: txn.walletClosingBalance,
            }))
          : [];

      const combinedData = [...payInData, ...payOutData];
      const sortedData = combinedData.sort(
        (a, b) => new Date(b.addedOn) - new Date(a.addedOn)
      );

      setTransactionData(sortedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching transaction data:", error);
      setTransactionData([]);
    }
  };

  useEffect(() => {
    fetchTransactionData();
  }, []);

  const generatePDF = () => {
    if (!transactionData || transactionData.length === 0) {
      alert("No transaction data available to generate PDF.");
      return;
    }

    const doc = new jsPDF();
    const logo = new Image();
    logo.src = iPaisaLogo;

    logo.onload = () => {
      doc.addImage(logo, "PNG", 10, 10, 30, 30); // Adjust position and size as needed
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("Transaction Statement", 50, 20);
      doc.setFontSize(12);
      doc.text("24 Jun, 2024 - 24 Jul, 2024", 50, 30);

      const tableColumn = ["Date", "Transaction Details", "Type", "Amount"];
      const tableRows = [];

      transactionData.forEach((row) => {
        // Destructure row properties to handle undefined issues
        const {
          addedOn,
          type,
          reciverName,
          txnId,
          utrNo,
          senderName,
          accountNumber,
          amount,
          walletOpeningBalance,
          walletClosingBalance,
          status,
          senderNumber,
          remark,
        } = row;

        // Check if the properties exist to avoid undefined values
        const rowData = [
          addedOn ? new Date(addedOn).toLocaleString() : "N/A",
          type === "Pay-Out"
            ? `Paid to: ${reciverName || "N/A"}\nTransaction ID: ${txnId || "N/A"}\nUTR No.: ${utrNo || "N/A"}\nPaid by: ${senderName || "N/A"}\nAccount Number: ${accountNumber || "N/A"}\nTransfer Amount: ${amount || "N/A"}\nWallet Opening Balance: ${Number(walletOpeningBalance || 0).toFixed(2)}\nWallet Closing Balance: ${Number(walletClosingBalance || 0).toFixed(2)}\nStatus: ${status || "N/A"}`
            : `Received from: ${senderName || "N/A"}\nTransaction ID: ${txnId || "N/A"}\nUTR No.: ${remark || "N/A"}\nCredited to: ${senderNumber || "N/A"}`,
          type || "N/A",
          amount ? `${amount}` : "N/A",
        ];

        tableRows.push(rowData);
      });

      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 50,
        styles: {
          fontSize: 10,
          cellPadding: 3,
          overflow: "linebreak",
        },
        headStyles: {
          fillColor: [22, 160, 133],
          textColor: [255, 255, 255],
          fontSize: 12,
        },
      });

      doc.save("Transaction_Statement.pdf");
    };
  };

  const downloadCSV = async () => {
    const jwtToken = localStorage.getItem("jwtToken");

    try {
      const response = await axios.get(
        "https://testapi.ipaisa.co.in/myapp/auth/badrecords",
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
          responseType: "text",
        }
      );

      const data = response.data; // Assuming the response is an array of records
      const headers = [
        "payeraccountNumber",
        "name",
        "payeeaccountNumber",
        "bankIfsc",
        "transferMode",
        "transferAmount",
        "latitude",
        "longitude",
        "remarks",
        "purpose",
      ];

      const csvRows = [];
      csvRows.push(headers.join(",")); // Add headers

      data.forEach((record) => {
        const values = headers.map((header) =>
          JSON.stringify(record[header] || "")
        ); // Escape values and handle missing data
        csvRows.push(values.join(","));
      });

      const csvContent = csvRows.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "bad_records.csv");
      link.click();
    } catch (error) {
      console.error("Error downloading CSV:", error);
      alert("Failed to download CSV.");
    }
  };

  const handleUnsuccessfulTransactionClick = async () => {
    await downloadCSV(); // Trigger CSV download
  };

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      style={{ padding: "20px", marginTop: "7%" }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h4"
          align="start"
          gutterBottom
          style={{ fontWeight: "bold" }}
        >
          PayIn and PayOut History
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "right", marginBottom: "10px" }}>
        <Button
          onClick={generatePDF}
          variant="contained"
          color="primary"
          style={{ textTransform: "none" }}
          startIcon={<DownloadIcon />}
        >
          Download PDF File
        </Button>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "right", marginBottom: "10px" }}>
        <Button
          onClick={handleUnsuccessfulTransactionClick}
          variant="outlined"
          color="secondary"
          style={{ textTransform: "none" }}
        >
          Download Bad Records CSV
        </Button>
      </Grid>
      <Grid item xs={12}>
        <BrowserView>
          <Paper elevation={3} style={{ padding: "20px" }}>
            <TableContainer>
              <Table>
                <TableHead
                  style={{ backgroundColor: "#027BFE", color: "white" }}
                >
                  <TableRow>
                    <TableCell style={{ color: "white" }} align="center">
                      Sr. No.
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="center">
                      Type
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="center">
                      Txn ID
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="center">
                      Utr No.
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="center">
                      Account No.
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="center">
                      Account Name
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="center">
                      Status
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="center">
                      Date
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="center">
                      Amount
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="center">
                      Surchage
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="center">
                      Total Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionData && transactionData.length > 0 ? (
                    transactionData.map((txn, index, row) => (
                      <TableRow
                        key={txn.id}
                        className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell
                          align="center"
                          className={
                            txn.type === "Pay-In"
                              ? classes.PayInText
                              : classes.PayOutText
                          }
                        >
                          {txn.type}
                        </TableCell>
                        <TableCell align="center">{txn.txnId}</TableCell>
                        <TableCell align="center">{txn.orderid}</TableCell>
                        <TableCell align="center">
                          {txn.accountNumber}
                        </TableCell>
                        <TableCell align="center">{txn.accountName}</TableCell>
                        <TableCell align="center">{txn.status}</TableCell>
                        <TableCell align="center">
                          {new Date(txn.addedOn).toLocaleString()}
                        </TableCell>
                        <TableCell align="center">{txn.amount}</TableCell>
                        <TableCell align="center">
                          {txn.surcharge + " " + "Rs"}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          {(Number(txn.amount) + Number(txn.surcharge)).toFixed(
                            2
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No transactions found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </BrowserView>
        <MobileView>
          <Paper elevation={3} style={{ padding: "20px" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Type</TableCell>
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">Txn ID</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Added On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionData && transactionData.length > 0 ? (
                    transactionData.map((txn) => (
                      <TableRow key={txn.id}>
                        <TableCell align="center">{txn.type}</TableCell>
                        <TableCell align="center">{txn.id}</TableCell>
                        <TableCell align="center">{txn.txnId}</TableCell>
                        <TableCell align="center">{txn.amount}</TableCell>
                        <TableCell align="center">{txn.status}</TableCell>
                        <TableCell align="center">
                          {new Date(txn.addedOn).toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No transactions found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </MobileView>
      </Grid>
    </Grid>
  );
};

export default PayInAndOut;
