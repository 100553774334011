import React, { useState, useEffect } from "react";
import {
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
  Pagination,
  TextField,
  Button,
} from "@mui/material";
import axios from "axios";

const UserDropdown = () => {
  const [userType, setUserType] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userRecords, setUserRecords] = useState([]);
  const [availableUserTypes, setAvailableUserTypes] = useState([]);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  // Search filter states
  const [searchTerm, setSearchTerm] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
    setSelectedUserId(null);
  };

  const handleUserChange = (event) => {
    setSelectedUserId(event.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const uType = localStorage.getItem("uType");
    if (uType === "MASTERDISTRIBUTOR") {
      setAvailableUserTypes(["AREADISTRIBUTOR", "RETAILER"]);
    } else if (uType === "AREADISTRIBUTOR") {
      setAvailableUserTypes(["RETAILER"]);
    } else {
      setAvailableUserTypes(["AREADISTRIBUTOR", "RETAILER"]);
    }
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      if (userType) {
        setLoading(true);
        try {
          const jwtToken = localStorage.getItem("jwtToken");
          const response = await axios.get(
            "https://testapi.ipaisa.co.in/myapp/auth/hira-payout-rsp-list",
            {
              headers: { Authorization: `Bearer ${jwtToken}` },
            }
          );

          const filteredUsers = response.data.filter(
            (user) => user.userRole === userType
          );
          setUsers(filteredUsers);
        } catch (error) {
          console.error("Error fetching users:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUsers();
  }, [userType]);

  useEffect(() => {
    if (selectedUserId) {
      const records = users.filter((user) => user.userId === selectedUserId);
      const sortedRecords = records.sort(
        (a, b) =>
          new Date(b.instantPayOut.timestamp) -
          new Date(a.instantPayOut.timestamp)
      );
      setUserRecords(sortedRecords);
    }
  }, [selectedUserId, users]);

  const uniqueUsers = Array.from(
    new Map(users.map((user) => [user.userId, user])).values()
  );

  const totalPages = Math.ceil(userRecords.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentRecords = userRecords.slice(
    startIndex,
    startIndex + rowsPerPage
  );

  const filteredRecords = currentRecords.filter((record) => {
    const searchLower = searchTerm.toLowerCase();
    const recordDate = new Date(record.instantPayOut.timestamp);
    const from = fromDate ? new Date(fromDate) : null;
    const to = toDate ? new Date(toDate) : null;

    const matchesSearch =
      record.instantPayOut.payeeName.toLowerCase().includes(searchLower) ||
      record.instantPayOut.payeeAccount.toLowerCase().includes(searchLower) ||
      record.userId.toLowerCase().includes(searchLower);

    const matchesDateRange =
      (!from || recordDate >= from) && (!to || recordDate <= to + "23:59:59");

    return matchesSearch && matchesDateRange;
  });

  const totalTransactionValue = filteredRecords
    .reduce((total, record) => {
      return total + parseFloat(record.instantPayOut.txnValue);
    }, 0)
    .toFixed(2);

  const handleSearch = () => {
    setCurrentPage(1);
  };

  const handleCancel = () => {
    setSearchTerm("");
    setFromDate("");
    setToDate("");
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ marginTop: "10%", justifyContent: "center" }}
    >
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ padding: "16px", width: "100%" }}>
          <Grid
            item
            xs={12}
            container
            spacing={2}
            justifyContent="space-evenly"
            sx={{ marginTop: "2%" }}
          >
            <Grid item xs={12} md={3} container justifyContent="space-evenly">
              <FormControl fullWidth sx={{ maxWidth: "300px" }}>
                <InputLabel>User Type</InputLabel>
                <Select
                  value={userType}
                  onChange={handleUserTypeChange}
                  label="User Type"
                >
                  {availableUserTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type === "AREADISTRIBUTOR"
                        ? "Area Distributor"
                        : "Retailer"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3} container justifyContent="space-evenly">
              {loading ? (
                <CircularProgress />
              ) : (
                <FormControl fullWidth sx={{ maxWidth: "300px" }}>
                  <InputLabel>User</InputLabel>
                  <Select
                    value={selectedUserId || ""}
                    onChange={handleUserChange}
                    label="User"
                    disabled={!uniqueUsers.length}
                  >
                    {uniqueUsers.map((user) => (
                      <MenuItem key={user.userId} value={user.userId}>
                        {user.userName} ({user.userRole})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                label="Search by Payee Name, Payee Account, or User ID"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={2}
            justifyContent="space-evenly"
            sx={{ marginTop: "2%" }}
          >
            <Grid item xs={12} md={3}>
              <TextField
                label="From Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="To Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={3} container justifyContent="space-between">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >
                Search
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* Table Container */}
      {filteredRecords.length > 0 && (
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            sx={{ width: "100%", overflowX: "auto", borderRadius: "12px" }}
          >
            <Table>
              <TableHead sx={{ backgroundColor: "#027BFE" }}>
                <TableRow>
                  <TableCell
                    sx={{ color: "white", width: "10%" }}
                    align="start"
                  >
                    User ID
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", width: "15%" }}
                    align="start"
                  >
                    User Name
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", width: "15%" }}
                    align="start"
                  >
                    Payee Account
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", width: "15%" }}
                    align="start"
                  >
                    Payee Name
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", width: "12%" }}
                    align="start"
                  >
                    Wallet Opening Balance
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", width: "10%" }}
                    align="start"
                  >
                    Txn Value
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", width: "12%" }}
                    align="start"
                  >
                    Wallet Closing Balance
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", width: "10%" }}
                    align="start"
                  >
                    Transfer Mode
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", width: "10%" }}
                    align="start"
                  >
                    Timestamp
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", width: "10%" }}
                    align="start"
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRecords.map((record) => (
                  <TableRow key={record.instantPayOut.txnId}>
                    <TableCell>{record.userId}</TableCell>
                    <TableCell>{record.instantPayOut.payeeName}</TableCell>
                    <TableCell>{record.instantPayOut.payeeAccount}</TableCell>
                    <TableCell>{record.instantPayOut.payeeName}</TableCell>
                    <TableCell>
                      {record.instantPayOut.walletOpeningBalance}
                    </TableCell>
                    <TableCell>{record.instantPayOut.txnValue}</TableCell>
                    <TableCell>
                      {record.instantPayOut.walletClosingBalance}
                    </TableCell>
                    <TableCell>{record.instantPayOut.transfer_mode}</TableCell>
                    <TableCell>
                      {new Date(
                        record.instantPayOut.timestamp
                      ).toLocaleString()}
                    </TableCell>
                    <TableCell
                      align="start"
                      style={{
                        color:
                          record.instantPayOut.status ===
                          "Transaction Successful"
                            ? "green"
                            : record.instantPayOut.status ===
                                "Transaction Pending"
                              ? "#B59410"
                              : record.instantPayOut.status === "REFUND"
                                ? "#754668"
                                : record.instantPayOut.status ===
                                    "Transaction Failed"
                                  ? "red"
                                  : "inherit", // Default text color
                        fontWeight: "bold",
                      }}
                    >
                      {record.instantPayOut.status === "Transaction Successful"
                        ? "Successful"
                        : record.instantPayOut.status === "Transaction Pending"
                          ? "Pending"
                          : record.instantPayOut.status === "REFUND"
                            ? "Refund"
                            : record.instantPayOut.status ===
                                "Transaction Failed"
                              ? "Failed"
                              : record.instantPayOut.status || "N/A"}{" "}
                      {/* Default value if no match */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container justifyContent="center" sx={{ marginTop: "16px" }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
              color="primary"
            />
          </Grid>
        </Grid>
      )}

      {/* Display Total Transaction Value */}
      {filteredRecords.length > 0 && (
        <Grid item xs={12}>
          <Paper
            elevation={3}
            sx={{ padding: "16px", width: "100%", marginTop: "16px" }}
          >
            <Typography variant="h6">
              Total Transaction Value: ₹ {totalTransactionValue}
            </Typography>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default UserDropdown;
