import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import iPaisaLogo from "../Assets/iPaisaLogo/iPaisaLogo.jpg"; // Adjust this path if needed
import jsPDF from "jspdf";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: "#C1E0FB",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
  PayOutText: {
    color: "red",
  },
}));

const PayOut = () => {
  const classes = useStyles();
  const [payOutData, setPayOutData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPayOutData = async () => {
      const jwtToken = localStorage.getItem("jwtToken");
      try {
        const response = await axios.get(
          "https://testapi.ipaisa.co.in/myapp/auth/pOuttTxnDetails",
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          }
        );
        setPayOutData(response.data.body || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching PayOut data:", error);
        setLoading(false);
      }
    };

    fetchPayOutData();
  }, []);

  const formatDate = (dateTimeString) => {
    if (!dateTimeString) return "Invalid Date"; // Handle undefined or null dateTimeString

    const [datePart, timePart] = dateTimeString.split("T");
    const [year, month, day] = datePart.split("-");

    // Check if parts are valid and formatted properly
    if (!datePart || !timePart || !year || !month || !day)
      return "Invalid Date";

    const formattedDate = `${day}-${month}-${year}`;
    return `${formattedDate} ${timePart}`;
  };

  const generatePDF = () => {
    if (!payOutData || payOutData.length === 0) {
      toast("No transaction data available to generate PDF.");
      return;
    }

    const doc = new jsPDF();
    const logo = new Image();
    logo.src = iPaisaLogo;

    logo.onload = () => {
      doc.addImage(logo, "PNG", 10, 10, 30, 30); // Adjust position and size as needed
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("Transaction Statement", 50, 20);
      doc.setFontSize(12);
      doc.text("24 Jun, 2024 - 24 Jul, 2024", 50, 30);

      const tableColumn = ["Date", "Transaction Details", "Type", "Amount"];
      const tableRows = [];

      payOutData.forEach((row) => {
        const rowData = [
          formatDate(row.addedon),
          row.type === "Pay-Out"
            ? `Paid to : ${row.reciverName}\nTransaction ID : ${row.txnId}\nUTR No. : ${row.utrNo}\nPaid by : ${row.senderName}\nAccount Number : ${row.accountNumber}\nTransfer Amount : ${row.amount}\nWallet Opening Balance : ${Number(row.walletOpeningBalance).toFixed(2)}\nWallet Closing Balance : ${Number(row.walletClosingBalance).toFixed(2)}\nStatus : ${row.status}`
            : `Received from ${row.senderName}\nTransaction ID ${row.txnId}\nUTR No. ${row.remark}\nCredited to ${row.senderNumber}`,
          row.type,
          `${row.amount}`,
        ];
        tableRows.push(rowData);
      });

      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 50,
        styles: {
          fontSize: 10,
          cellPadding: 3,
          overflow: "linebreak",
        },
        headStyles: {
          fillColor: [22, 160, 133],
          textColor: [255, 255, 255],
          fontSize: 12,
        },
      });

      doc.save("Transaction_Statement.pdf");
    };
  };

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container style={{ padding: "20px", marginTop: "9%" }}>
      <Typography
        variant="h4"
        align="start"
        gutterBottom
        style={{ fontWeight: "bold" }}
      >
        PayOut History
      </Typography>
      <Grid item xs={12} style={{ textAlign: "right", marginBottom: "10px" }}>
        <Button
          onClick={generatePDF}
          variant="contained"
          color="primary"
          style={{ textTransform: "none" }}
          startIcon={<DownloadIcon />}
        >
          Download PDF File
        </Button>
      </Grid>

      <Grid style={{ marginTop: "3%" }}>
        <TableContainer
          component={Paper}
          sx={{
            maxWidth: "100%",
            overflowX: "auto",
            borderRadius: "12px 12px 0px 0px",
          }}
        >
          <Table>
            <TableHead style={{ backgroundColor: "#027BFE", color: "white" }}>
              <TableRow>
                <TableCell style={{ color: "white" }} align="center">
                  Sr. No.
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                  Type
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                  Txn ID
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                  Account No.
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                  Account Name
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                  Status
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                  Date
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                  Amount
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                  Surcharge
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                  Total Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payOutData && payOutData.length > 0 ? (
                payOutData.map((txn, index) => (
                  <TableRow
                    key={txn.id}
                    className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      Pay-Out
                      <CallMadeIcon />
                    </TableCell>
                    <TableCell align="center">{txn.txnReferenceId}</TableCell>
                    <TableCell align="center">{txn.poolAccount}</TableCell>
                    <TableCell align="center">{txn.payeeName}</TableCell>
                    <TableCell align="center">{txn.status}</TableCell>
                    <TableCell align="center">
                      {formatDate(txn.timestamp)}
                    </TableCell>
                    <TableCell align="center">{txn.txnValue}</TableCell>
                    <TableCell align="center">{txn.poolAmount}</TableCell>
                    <TableCell align="center">
                      {(
                        parseFloat(txn.txnValue) + parseFloat(txn.poolAmount)
                      ).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    No transactions found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default PayOut;
