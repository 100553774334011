import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import AddUserDialog from "./AddUserDialog";
import "react-toastify/dist/ReactToastify.css";
import EditUserDialog from "./EditUserDialog";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Grid,
  TablePagination,
  Switch,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CardMedia,
  Card,
  InputBase,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, Edit, Delete } from "@mui/icons-material";
import axios from "axios";
import SearchNormalIcon from "../Assets/Partner/Search.svg";
import AddUserIcon from "../Assets/Partner/Add.svg";
import ViewUserDialog from "./ViewUserDialog";
import { useSelector, useDispatch } from "react-redux";
import {
  deletePartner,
  fetchPartners,
  togglePartnerStatus,
  updatePartners,
} from "../../redux/actions/partnerActions";
import { makeStyles } from "@mui/styles";
import UserCreationSuccessPopup from "./UserCreationSuccessPopup";
import UserCreationFailedPopup from "./UserCreationFailedPopup";

const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: "#C1E0FB",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
}));

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  middleName: Yup.string().required("Middle Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  dob: Yup.date().required("Date of Birth is required"),
  mobileNumber: Yup.string().required("Mobile Number is required"),
  alternateMobileNumber: Yup.string().required(
    "Alternate Mobile Number is required"
  ),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const SdlistTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const partners = useSelector((state) => state.partner.partners);
  const superdistributor = partners?.SUPERDISTRIBUTOR || [];
  const [showSuccessPop, setShowSuccessPop] = useState(false);
  const [showFailedPop, setShowFailedPop] = useState(false);

  const handleClosePop = () => {
    setShowSuccessPop(false);
    setShowFailedPop(false);
  };

  useEffect(() => {
    dispatch(fetchPartners());
  }, [dispatch]);

  useEffect(() => {
    console.log("Fetched Partners:", partners);
  }, [partners]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [userType, setUserType] = useState("");
  const [viewRow, setViewRow] = useState(null);

  useEffect(() => {
    const storedUserType = localStorage.getItem("uType");
    setUserType(storedUserType);
  }, []);

  const [newUser, setNewUser] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    mobileNumber: "",
    alternateMobileNumber: "",
    email: "",
    country: null,
    state: null,
    city: null,
  });
  const [addUserOpen, setAddUserOpen] = useState(false);
  const handleSearchClick = () => {
    setShowSearchInput(!showSearchInput);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setViewRow(row);
    setOpen(true);
  };

  const handleEditOpen = (row) => {
    setEditRow(row);
    setEditOpen(true);
  };

  const handleAddUserOpen = () => {
    setAddUserOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setEditRow(null);
  };

  const handleSaveEdit = async (editedUser) => {
    const editedIndex = partners.findIndex(
      (user) => user.userid === editedUser.userid
    );
    if (editedIndex !== -1) {
      try {
        const token = localStorage.getItem("jwtToken");
        await axios.post(
          "https://ipaisa.co.in/myapp/auth/updateUser",
          editedUser,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const newData = [...partners];
        newData[editedIndex] = editedUser;

        // Dispatch the update user action
        dispatch(updatePartners(editedUser));

        toast.success("User details updated successfully");
        setShowSuccessPop(true);
      } catch (error) {
        console.error("Error updating user:", error);
        toast.error("Error updating user details. Please try again later.");
        setShowFailedPop(true);
      }
    } else {
      toast.error("Error updating user details");
      setShowFailedPop(true);
    }
  };

  const handleAddUser = (values) => {
    // console.log('New User:', values);
    // toast.success('User added successfully');
    setShowSuccessPop(true);
  };

  const handleAddUserClose = () => {
    setAddUserOpen(false);
    dispatch(fetchPartners());
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (editOpen) {
      setEditRow({ ...editRow, [name]: value });
    } else {
      setNewUser({ ...newUser, [name]: value });
    }
  };

  const handleDelete = (sdId) => {
    // Call the deletePartner action, which handles the API call and Redux update
    dispatch(deletePartner(sdId));
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleStatusToggle = async (sdId, currentStatus) => {
    try {
      const token = localStorage.getItem("jwtToken");
      const newStatus = currentStatus === "Active" ? "Inactive" : "Active";

      const response = await axios.put(
        `https://testapi.ipaisa.co.in/myapp/admin/changeStatus/${sdId}`,
        { status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Check the response status and show the appropriate toast message
      if (response.data.message === "true") {
        dispatch(togglePartnerStatus(sdId));
        toast.success(response.data.status); // Success message from the response
        dispatch(fetchPartners());
      } else {
        toast.error(`Error: ${response.data.status}`);
      }
    } catch (error) {
      console.error("Error toggling user status:", error);
      toast.error("Error toggling user status. Please try again later.");
    }
  };

  const filteredData = superdistributor.filter((row) => {
    return Object.values(row).some((value) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const inputHeight = "56px";

  return (
    <Container maxWidth="xl" style={{ marginTop: "12%" }}>
      <Box mt={4} mb={2}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Grid>
            <Typography style={{ fontWeight: "bold", fontSize: "22px" }}>
              Super Distributors
            </Typography>
          </Grid>
          <Grid
            style={{ display: "flex", gap: "2%" }}
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid item>
              <TextField
                label="Search"
                variant="outlined"
                onChange={(e) => setSearchQuery(e.target.value)}
                onClick={() => setShowSearchInput(!showSearchInput)}
              />
            </Grid>
            {userType !== "RETAILER" && (
              <Grid item>
                <Button
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    height: inputHeight,
                  }}
                  onClick={handleAddUserOpen}
                >
                  <ControlPointIcon style={{ marginRight: 8 }} />
                  Add User
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <TableContainer component={Paper} style={{ marginTop: "2%" }}>
          <Table>
            <TableHead style={{ backgroundColor: "#027BFE" }}>
              <TableRow>
                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                  Sr.No
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                  User ID
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                  Parent ID
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", textAlign: "start" }}>
                  Name
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", textAlign: "start" }}>
                  Business Name
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                  Mobile Number
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                  Wallet Balance
                </TableCell>
                {userType === "ADMIN" ? (
                  <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                    Status
                  </TableCell>
                ) : (
                  <></>
                )}
                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row.userid}
                    className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                  >
                    <TableCell sx={{ textAlign: "center" }}>
                      {index + 1 + page * rowsPerPage}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.userid}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.parentId}
                    </TableCell>
                    <TableCell sx={{ textAlign: "start" }}>
                      {row.firstName} {row.middleName} {row.lastName}
                    </TableCell>
                    <TableCell sx={{ textAlign: "start" }}>
                      {row.bussinessName}
                    </TableCell>
                    {/* <TableCell sx={{ textAlign: 'center' }}>{row.pincode}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{row.email}</TableCell> */}
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.mobileNumber}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.walletBalance}
                    </TableCell>
                    {userType === "ADMIN" ? (
                      <TableCell sx={{ textAlign: "center" }}>
                        {userType === "ADMIN" ? (
                          <Switch
                            onChange={() =>
                              handleStatusToggle(row.userid, row.status)
                            }
                            checked={row.status === "ACTIVE"}
                            sx={{
                              "& .MuiSwitch-switchBase.Mui-checked": {
                                color: "green",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                {
                                  backgroundColor: "green",
                                },
                              "& .MuiSwitch-switchBase": {
                                color: "red",
                              },
                              "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                                backgroundColor: "red",
                              },
                            }}
                          />
                        ) : (
                          <Switch
                            checked={row.status === "ACTIVE"}
                            sx={{
                              "& .MuiSwitch-switchBase.Mui-checked": {
                                color: "green",
                              },
                              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                {
                                  backgroundColor: "green",
                                },
                              "& .MuiSwitch-switchBase": {
                                color: "red",
                              },
                              "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                                backgroundColor: "red",
                              },
                            }}
                            disabled
                          />
                        )}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    <TableCell sx={{ textAlign: "center" }}>
                      <IconButton onClick={() => handleClickOpen(row)}>
                        <Visibility />
                      </IconButton>
                      {userType === "ADMIN" && (
                        <>
                          <IconButton onClick={() => handleEditOpen(row)}>
                            <Edit />
                          </IconButton>
                          <IconButton onClick={() => handleDelete(row.userid)}>
                            <Delete />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <AddUserDialog
        open={addUserOpen}
        handleClose={handleAddUserClose}
        handleAddUser={handleAddUser}
      />
      <EditUserDialog
        open={editOpen}
        handleClose={handleEditClose}
        handleSaveEdit={handleSaveEdit}
        editRow={editRow}
        handleInputChange={handleInputChange}
      />

      <ViewUserDialog
        open={open}
        handleClose={handleClose}
        selectedRow={selectedRow}
        user={viewRow}
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        pauseOnHover
      />
      {showSuccessPop && (
        <UserCreationSuccessPopup handleClose={handleClosePop} />
      )}
      {showFailedPop && (
        <UserCreationFailedPopup handleClose={handleClosePop} />
      )}
    </Container>
  );
};

export default SdlistTable;
