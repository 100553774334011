import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Download, FilterAlt } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import * as writeXLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: "#D0EFFF",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
}));

const VirtualTrans = () => {
  const [trnsList, setTranslist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [pageFrom, setPageFrom] = useState(0); // Total pages
  const [pageTo, setPageTo] = useState(0); // Total pages
  const [pageSize, setPageSize] = useState(50);
  const [hasNext, setHasNext] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState(null); // For filter icon menu
  const [current, setCurrent] = useState(1);
  const [accList, setAccList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(inputValue);
  const [openDownloadWindow, setOpenDownloadWindow] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const userType = localStorage.getItem("uType");
  const [filters, setFilters] = useState({
    id: "",
    startDate: dayjs().format("YYYY-MM-DD"), // Default to 30 days ago
    endDate: dayjs().format("YYYY-MM-DD"), // Default to today
    utr: "",
    virtualId: "",
  }); // Filter states
  var totalAmount = 0;
  const classes = useStyles();
  const navigate = useNavigate();

  const statusColors = {
    received: "#67C529",
    pending: "#E8DC22",
    uncaptured: "#E8DC22",
    "timed out": "#E29D95",
    failure: "#FF0000",
    unsettled: "#E8DC22",
    "partially refunded": "#3D8AC5",
    refunded: "#655AA1",
  };

  const handleSearchInputChange = (event, newValue) => {
    console.log(newValue);
    setInputValue(newValue);
    setFilters((prevFilters) => ({
      ...prevFilters,
      virtualId: newValue.id || "", // Update virtualId in filters
    }));
  };

  const handleClickOpenDownloadWindow = () => {
    setOpenDownloadWindow(true);
  };

  const handleCloseDownloadWindow = () => {
    console.log("close window clicked");
    setOpenDownloadWindow(false);
  };

  // Handle filter menu open/close
  const handleFilterMenuClick = (event) => {
    setFilterMenuAnchor(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchList(newPage);
  };
  // Separate function to handle DatePicker change
  const handleDateChange = (key, newValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: newValue ? dayjs(newValue).format("YYYY-MM-DD") : "",
    }));
  };

  // Function to handle filter changes for text inputs
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  // Apply filters and call the fetchList function with page 1
  const applyFilters = () => {
    handleFilterMenuClose();
    if (
      filters.endDate &&
      filters.startDate &&
      dayjs(filters.endDate).isBefore(dayjs(filters.startDate))
    ) {
      // toast.error("End Date cannot be less than Start Date.");
      Swal.fire({
        title: "Something Went Wrong..!!",
        text: "End Date cannot be less than Start Date.",
        icon: "error",
      });
      return;
    } else {
      // setErrorMessage(""); // Clear error if validation passes
      fetchList(1); // Trigger fetch with the current filters
    }
    // console.log("Filters applied:", filters); // Debugging
  };

  const fetchAccList = async (curr) => {
    const jwtToken = localStorage.getItem("jwtToken");
    // console.log(curr);
    try {
      const response = await axios.get(
        "https://testapi.ipaisa.co.in/myapp/virtual/fetchallaccounts",
        {
          headers: { Authorization: `Bearer ${jwtToken}`, current: curr },
        }
      );
      console.log(response);

      if (response.status !== 200) {
        // toast.error("Error with " + response.status);
        Swal.fire({
          title: "Something Went Wrong..!!",
          text: "With Status Code" + response.status,
          icon: "error",
        });
      }

      var tpages = response.data.data.total_pages;

      var list = response.data.data.results;
      // console.log(list);
      // Append new accounts to the existing list
      setAccList((prevAccList) => {
        const uniqueAccList = [...prevAccList, ...list].reduce(
          (acc, current) => {
            if (!acc.find((item) => item.id === current.id)) {
              acc.push(current);
            }
            return acc;
          },
          []
        );

        return uniqueAccList;
      });
      // Check if we need to fetch more accounts
      if (curr < tpages) {
        curr = curr + 1;
        fetchAccList(curr); // Fetch the next page
        setCurrent(curr);
      }
      //   console.log(response);
    } catch (e) {}
  };

  const handleDownload = async () => {
    // console.log("download clicked");
    setPageSize(1000);
    setPageSize(1000);
    try {
      // Assuming fetchList is asynchronous and returns the data

      const response = await fetchList(1);
      console.log("thisis resp", response);
      const dataList = response;

      if (!dataList || !dataList.length) {
        console.error("No data available to download.");
        handleCloseDownloadWindow();
        Swal.fire({
          title: "Transactions Report",
          text: "There is no Transactions",
          icon: "info",
          timer: 2000,
        });
        return;
      }

      // Format the data for Excel (select necessary fields)
      const formattedData = dataList.map((transaction) => ({
        TransactionID: transaction.id,
        Status: transaction.status,
        transaction_date: transaction.transaction_date,
        CreatedAt: transaction.created_at,
        PaymentMode: transaction.payment_mode,
        Narration: transaction.narration,
        UTR: transaction.unique_transaction_reference,
        RemitterName: transaction.remitter_full_name,
        RemitterUPI: transaction.remitter_upi_handle,
        Amount: transaction.amount,
        VirtualAccount: transaction.virtual_account.virtual_account_number,
        VirtualAccountUPI: transaction.virtual_account.virtual_upi_handle,
      }));

      // Create a worksheet from the data
      const worksheet = writeXLSX.utils.json_to_sheet(formattedData);

      // Create a new workbook and add the worksheet
      const workbook = writeXLSX.utils.book_new();
      writeXLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");

      // Create and download the Excel file
      writeXLSX.writeFile(workbook, "UPI_Transactions_Report.xlsx");
      handleCloseDownloadWindow();
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  };

  const fetchList = async (page) => {
    const jwtToken = localStorage.getItem("jwtToken");
    // console.log(filteredTransactions + " this is filter");

    var tosendpagsize = pageSize;
    console.log(tosendpagsize);
    // Prepare the params based on the filters state
    const params = {
      created_at: ``,
      created_at_start: filters.startDate
        ? dayjs(filters.startDate).format("YYYY-MM-DD")
        : ``,
      created_at_end: filters.endDate
        ? dayjs(filters.endDate).format("YYYY-MM-DD")
        : ``,
      current: page,
      order_id: ``,
      pageSize: tosendpagsize,
      status: filters.status || ``,
      unique_transaction_reference: filters.utr || ``,
      virtual_account_id: filters.virtualId || ``,
    };

    try {
      const response = await axios.get(
        "https://testapi.ipaisa.co.in/myapp/virtual/transactions",
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
          params: params, // Use the dynamic params here
        }
      );
      // console.log(response.data.data);
      const resultsArr = response.data.data.results;
      const settingHasNext = response.data.data.pagination.hasNext;
      const settingPageFrom = response.data.data.pagination.showing.from;
      const settingPageTo = response.data.data.pagination.showing.to;
      // console.log(resultsArr);
      setTranslist(resultsArr);
      setHasNext(settingHasNext);
      setPageFrom(settingPageFrom);
      setPageTo(settingPageTo);
      // console.log(resultsArr);
      return resultsArr;
    } catch (error) {
      console.error("Error fetching PayIn data:", error);
    }
  };

  const onHRClick = () => {
    navigate("/htrans");
  };
  // Debounce the handler
  const debouncedHandler = debounce((value) => {
    setDebouncedValue(value);
  }, 300); // Debounce delay of 300ms

  useEffect(() => {
    setAccList([]); // Clear the account list on component mount/refresh
    fetchList(currentPage); // Fetch transaction list
    fetchAccList(1); // Fetch accounts list
    debouncedHandler(inputValue);
    // Cleanup function to cancel the debounce on unmount or input change
    return () => {
      debouncedHandler.cancel();
    };
  }, []);

  return (
    <>
      <Container
        style={{
          marginBottom: "4%",
          borderRadius: "8px",
          marginTop: "calc(3% + 56px)",
          backgroundColor: "#background: #F5F7FA",
        }}
      >
        <h2>This is Transaction List</h2>

        {/* Filter Button with Icon */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <IconButton onClick={handleFilterMenuClick}>
            <FilterAlt />
          </IconButton>
          <Box display="flex" alignItems="center">
            <IconButton onClick={handleClickOpenDownloadWindow}>
              <Typography>Download Report </Typography> <Download />
            </IconButton>
            <Typography
              onClick={onHRClick}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                marginLeft: 2,
              }}
            >
              Hierarchy-wise Reports
            </Typography>
          </Box>

          <Dialog
            onClick={handleClickOpenDownloadWindow}
            fullScreen={fullScreen}
            open={openDownloadWindow}
            onClose={handleCloseDownloadWindow}
          >
            <DialogTitle id="responsive-dialog-title">
              Download Report
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                You can Download Upto 1000 Transactions
              </DialogContentText>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "100%", marginTop: 2 }}
                  label="Start Date"
                  value={filters.startDate ? dayjs(filters.startDate) : null}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                  onChange={(newValue) =>
                    handleDateChange("startDate", newValue)
                  }
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "100%", marginTop: 2 }}
                  label="End Date"
                  value={filters.endDate ? dayjs(filters.endDate) : null}
                  onChange={(newValue) => handleDateChange("endDate", newValue)}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                />
              </LocalizationProvider>
              <Autocomplete
                sx={{ width: "100%", marginTop: 2 }}
                options={accList}
                loading={!accList.length} // Show a spinner until data is loaded
                getOptionLabel={(option) =>
                  `${option.label} ${option.virtual_upi_handle || ""} `
                }
                inputValue={inputValue}
                onInputChange={(event, value) => {
                  // value will be the entire selected object
                  console.log("Selected value:", value); // Log the selected value
                  setInputValue(value);
                  if (value) {
                  } else {
                    console.log("No value selected");
                  }
                }}
                onChange={(event, value) => {
                  // This will handle the selection of the option (the entire object)
                  console.log("Selected object:", value); // Log the selected object
                  if (value) {
                    handleSearchInputChange(event, value); // Pass the selected object here
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search with label"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {!accList.length ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                  />
                )}
              />
            </DialogContent>
            <DialogActions>
              {/* <Button autoFocus onClick={handleCloseDownloadWindow}>
                Cancel
              </Button> */}

              <Button autoFocus onClick={handleDownload}>
                <Download /> Download
              </Button>
            </DialogActions>
          </Dialog>

          {/* Filter Menu */}
          <Menu
            anchorEl={filterMenuAnchor}
            open={Boolean(filterMenuAnchor)}
            onClose={handleFilterMenuClose}
            disableAutoFocus
            disableEnforceFocus
          >
            <MenuItem>
              <TextField
                label="Transaction ID"
                name="id"
                value={filters.id}
                onChange={handleFilterChange}
                variant="outlined"
                onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                fullWidth
                autoFocus
              />
            </MenuItem>

            <MenuItem>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={filters.startDate ? dayjs(filters.startDate) : null}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                  onChange={(newValue) =>
                    handleDateChange("startDate", newValue)
                  }
                />
              </LocalizationProvider>
            </MenuItem>

            <MenuItem>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  value={filters.endDate ? dayjs(filters.endDate) : null}
                  onChange={(newValue) => handleDateChange("endDate", newValue)}
                  onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                />
              </LocalizationProvider>
            </MenuItem>

            <MenuItem>
              <TextField
                label="UTR Number"
                name="utr"
                value={filters.utr}
                onChange={handleFilterChange}
                variant="outlined"
                onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                fullWidth
              />
            </MenuItem>
            {userType === "ADMIN" && (
              <MenuItem>
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={accList}
                  loading={!accList.length} // Show a spinner until data is loaded
                  getOptionLabel={(option) =>
                    `${option.label} ${option.virtual_upi_handle || ""} `
                  }
                  inputValue={inputValue}
                  onInputChange={(event, value) => {
                    // value will be the entire selected object
                    console.log("Selected value:", value); // Log the selected value
                    setInputValue(value);
                    if (value) {
                    } else {
                      console.log("No value selected");
                    }
                  }}
                  onChange={(event, value) => {
                    // This will handle the selection of the option (the entire object)
                    console.log("Selected object:", value); // Log the selected object
                    if (value) {
                      handleSearchInputChange(event, value); // Pass the selected object here
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search with label"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {!accList.length ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      onKeyDown={(e) => e.stopPropagation()} // Prevent default focus behavior
                    />
                  )}
                />
              </MenuItem>
            )}
            <MenuItem>
              <Button variant="contained" onClick={applyFilters}>
                Apply Filters
              </Button>
            </MenuItem>
          </Menu>
        </Box>

        {/* Table Container Started...!!! */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead
              style={{
                backgroundColor: "#027BFE",
                color: "white",
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Sr. No.
                </TableCell>
                {/* <TableCell style={{ color: 'white' }} align="center">Type</TableCell> */}
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Remitter Full Name
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Status
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Payment Mode
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Amount
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  UTR Number
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Transaction Date
                </TableCell>
                {/* <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Full Name
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Phone No.
                </TableCell> */}
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  UPI ID/Handle
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Account No.
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  IFSC No.
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Label
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Virtual UPI Handle
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trnsList && trnsList.length > 0 ? (
                trnsList.map((txn, i) => {
                  if (txn.status === "failure") {
                  } else {
                    totalAmount = totalAmount + txn.amount;
                  }
                  return (
                    <TableRow
                      key={txn.id} // Use unique key based on the transaction ID
                      className={`${classes.tableRow} ${
                        i % 2 === 0 ? classes.oddRow : classes.evenRow
                      }`}
                    >
                      <TableCell>{i + 1}</TableCell> {/* Serial Number */}
                      <TableCell>{txn.remitter_full_name}</TableCell>{" "}
                      {/* Transaction ID */}
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          {/* Colored dot */}
                          <Box
                            sx={{
                              height: 10,
                              width: 10,
                              borderRadius: "50%",
                              backgroundColor:
                                statusColors[txn.status] || "black", // Default color if status is not found
                              marginRight: 1, // Space between dot and status text
                            }}
                          />
                          {/* <Box
                          sx={{
                            height: 10,
                            width: 10,
                            borderRadius: "50%",
                            backgroundColor:
                              txn.status === "received" ? "green" : "red", // Set color based on status
                            marginRight: 1, // Space between dot and status text
                          }}
                        /> */}
                          {/* Status text */}
                          <Typography>{txn.status}</Typography>
                        </Box>
                      </TableCell>
                      {/* Transaction Status */}
                      <TableCell>{txn.payment_mode}</TableCell>
                      {/* Payment Mode */}
                      <TableCell>{txn.amount}</TableCell>
                      {/* {(totalAmount = totalAmount + txn.amount)} */}
                      {/* Transaction Amount */}
                      <TableCell>{txn.unique_transaction_reference}</TableCell>
                      {/* UTR */}
                      {/* Remitter Name */}
                      <TableCell>
                        {dayjs(txn.transaction_date).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      </TableCell>
                      {/* {"Transaction Date"} */}
                      {/* Remitter Phone */}
                      {/* <TableCell>{txn.remitter_full_name}</TableCell> */}
                      {/* {"Remitter Name "} */}
                      {/* UPI Handle */}
                      {/* <TableCell>{txn.remitter_phone_number}</TableCell> */}
                      {/* {"Remitter Phone Number "} */}
                      {/* Creation Date */}
                      <TableCell>{txn.remitter_upi_handle}</TableCell>
                      {/* {"Remitter UPI "} */}
                      {/* Settlement Date */}
                      <TableCell>{txn.remitter_account_number}</TableCell>
                      {/* {"Account Number "} */}
                      {/* Bank Settlement Date */}
                      <TableCell>{txn.remitter_account_ifsc}</TableCell>
                      {/* {"IFSC "} */}
                      {/* Virtual Account */}
                      <TableCell>{txn.virtual_account.label}</TableCell>
                      {/* {"Virtual Label "} */}
                      <TableCell>
                        {txn.virtual_account.virtual_upi_handle}
                      </TableCell>
                      {/* {"Virtual UPI "} */}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    No transactions available
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell colSpan={9} align="center" sx={{ width: "100%" }}>
                  <Typography>Total Amount = Rs. {totalAmount}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <Button
                    variant="outlined"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage <= 1}
                    size="small"
                    sx={{ marginRight: 1 }}
                  >
                    Back
                  </Button>
                  <span>
                    Showing Transactions From {pageFrom} To {pageTo}
                  </span>
                  <Button
                    variant="outlined"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={!hasNext}
                    size="small"
                    sx={{ marginLeft: 1 }}
                  >
                    Next
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default VirtualTrans;
