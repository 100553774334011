import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Container,
  Grid,
  IconButton,
  InputBase,
  Typography,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TextField,
  MenuItem,
  Box,
  TablePagination,
  Grid2,
} from "@mui/material";
import { DownloadIcon, PartnerArrowIcon } from "../../res/NewWebIcons";
import Pagination from "@mui/material/Pagination";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { makeStyles } from "@mui/styles";
import DownloadIcon1 from "@mui/icons-material/Download";
import axios from "axios";
import iPaisaLogo from "../Assets/iPaisaLogo/iPaisaLogo.jpg"; // Adjust this path if needed
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import PayoutReportInvoice from "./PayoutReportInvoice";

const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: "#D0EFFF",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
  PayInText: {
    color: "green",
  },
}));

const NewPayOutReport = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const classes = useStyles();
  const [payOutData, setPayOutData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [userType, setUserType] = useState(""); // to track the selected category
  const [userList, setUserList] = useState([]); // to store the list of users
  const [userSearchQuery, setUserSearchQuery] = useState("");
  const [showUserList, setShowUserList] = useState(false);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [extraData, setExtraData] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Adjust the initial number as needed

  useEffect(() => {
    const fetchPayOutData = async () => {
      const jwtToken = localStorage.getItem("jwtToken");
      try {
        const response1 = await axios.get(
          "https://testapi.ipaisa.co.in/myapp/auth/pOuttTxnDetails",
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          }
        );
        console.log("API 1 Response:", response1.data);
        const data = Array.isArray(response1.data.body)
          ? response1.data.body
          : [];

        // Sort data by timestamp in descending order
        const sortedData = data.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        );
        setPayOutData(sortedData);
        setFilteredData(sortedData); // Set sorted data as filtered data initially

        // Fetch second API data
        const response2 = await axios.get(
          "https://testapi.ipaisa.co.in/myapp/auth/hira-payout-rsp-list",
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          }
        );
        console.log("API 2 Response:", response2.data);
        setExtraData(
          Array.isArray(response2.data.body) ? response2.data.body : []
        );
      } catch (error) {
        console.error("Error fetching PayOut data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPayOutData();
  }, []);

  const paginatedData = filteredData.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset to the first page
  };

  const formatDate = (dateTimeString) => {
    if (!dateTimeString) return "Invalid Date";
    const [datePart, timePart] = dateTimeString.split("T");
    const [year, month, day] = datePart.split("-");
    if (!datePart || !timePart || !year || !month || !day)
      return "Invalid Date";
    return `${day}-${month}-${year} ${timePart}`;
  };
  useEffect(() => {
    const searchFilteredData = payOutData.filter((txn) => {
      return (
        (txn.payeeName &&
          txn.payeeName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (txn.txnReferenceId &&
          txn.txnReferenceId.toString().includes(searchQuery)) ||
        (txn.payeeAccount && txn.payeeAccount.toString().includes(searchQuery))
      ); // Additional fields to search
    });

    // Sort the filtered data in descending order
    const sortedFilteredData = searchFilteredData.sort(
      (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
    );
    setFilteredData(sortedFilteredData);
  }, [searchQuery, payOutData]);

  const handleDownloadPDF = async (data) => {
    const receiptElement = document.getElementById(
      `receipt-${data.txnReferenceId}`
    );

    if (!receiptElement) {
      console.error(
        "Receipt element not found:",
        `receipt-${data.txnReferenceId}`
      );
      return;
    }

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5], // top, left, bottom, right in inches
      filename: `receipt_${data.txnReferenceId}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "in",
        orientation: "landscape",
        format: "a4",
        margin: 0.5,
      },
    };

    try {
      await html2pdf().from(receiptElement).set(opt).save();
    } catch (error) {
      console.error("Error generating PDF: ", error);
    }
  };

  const downloadCSV = () => {
    const csvRows = [];
    const headers = [
      "Sr. No.",
      "Type",
      "Txn ID",
      "Utr No.",
      "Account No.",
      "Account Name",
      "Status",
      "Date",
      "Amount",
      "Surcharge",
      "Total Amount",
      "Wallet Opening Balance",
      "Wallet Closing Balance",
    ];
    csvRows.push(headers.join(","));

    if (filteredData.length > 0) {
      filteredData.forEach((row, index) => {
        const {
          timestamp,
          txnReferenceId,
          externalRef,
          txnValue,
          status,
          payeeAccount,
          payeeName,
          walletOpeningBalance,
          walletClosingBalance,
          poolAmount,
        } = row;

        const rowData = [
          index + 1, // Sr. No.
          "Pay-Out", // Type
          Number(txnReferenceId) || "N/A", // Txn ID
          Number(externalRef) || "N/A", // Utr No.
          payeeAccount || "N/A", // Account No.
          payeeName || "N/A", // Account Name
          status || "N/A", // Status
          timestamp ? new Date(timestamp).toLocaleString() : "N/A", // Date
          txnValue ? `${txnValue}` : "N/A", // Amount
          poolAmount ? `${poolAmount}` : "N/A", // Surcharge
          poolAmount
            ? `${Number(txnValue) + Number(poolAmount)}`
            : `${txnValue}`,
          walletOpeningBalance.toFixed(2) || "N/A",
          walletClosingBalance.toFixed(2) || "N/A",
        ];

        csvRows.push(rowData.map((cell) => `"${cell}"`).join(","));
      });
    }

    const csvContent = `data:text/csv;charset=utf-8,${csvRows.join("\n")}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "Transaction_Statement.csv");
    document.body.appendChild(link);
    link.click();
  };

  const [selectedUser, setSelectedUser] = useState(null);
  const handleUserClick = (user) => {
    setSelectedUser(user); // Set the selected user
    // Optionally filter `filteredData` based on user
    const userFilteredData = payOutData.filter(
      (row) => row.payeeName === user.name
    ); // Adjust based on how you identify user details
    setFilteredData(userFilteredData);
  };
  const handleCancel = () => {
    setSelectedUser(null);
    setUserSearchQuery("");
    // Reset the filtered data if needed
    setFilteredData(payOutData);
  };
  // const filteredUserList = userList.filter(user => user.name.toLowerCase().includes(userSearchQuery.toLowerCase()));

  const handleSearch = () => {
    if (!fromDate || !toDate) {
      // alert('Please select both From and To dates');
      return;
    }

    const filtered = payOutData.filter((txn) => {
      const txnDate = new Date(txn.timestamp).toISOString().split("T")[0];
      return txnDate >= fromDate && txnDate <= toDate;
    });

    setFilteredData(filtered);
  };

  useEffect(() => {
    const searchFilteredData = payOutData.filter((txn) => {
      return (
        (txn.payeeName &&
          txn.payeeName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (txn.txnReferenceId &&
          txn.txnReferenceId.toString().includes(searchQuery)) ||
        (txn.payeeAccount && txn.payeeAccount.toString().includes(searchQuery))
      ); // Additional fields to search
    });
    setFilteredData(searchFilteredData);
  }, [searchQuery, payOutData]);

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  const handleClear = () => {
    setSearchQuery(""); // Clear search query
    setFromDate(""); // Clear fromDate
    setToDate(""); // Clear toDate
    setFilteredData(payOutData); // Reset filtered data to the original payInData
    // setCurrentPage(1);       // Reset to first page
  };

  return (
    <Container style={{ padding: "2rem", marginTop: "calc(3% + 56px)" }}>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1%",
          justifyContent: "start",
        }}
      >
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "12px" }}
        >
          Report
        </Typography>
        <PartnerArrowIcon />
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "12px" }}
        >
          Pay Out Report
        </Typography>
      </Grid>
      <Grid>
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1%",
          }}
        >
          <Grid>
            <Typography
              style={{
                fontWeight: "bold",
                fontFamily: "sans-serif",
                fontSize: "19px",
                color: "#343C6A",
              }}
            >
              Pay Out Report
            </Typography>
          </Grid>
          <Grid style={{ display: "flex", gap: "1rem" }}>
            <Button
              style={{
                textTransform: "none",
                backgroundColor: "#027BFE",
                color: "#FFFFFF",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "8px 16px",
                fontFamily: "sans-serif",
                fontSize: "14px",
                whiteSpace: "nowrap", // Ensure text stays in one line
                gap: "5%",
                height: "40px",
              }}
              onClick={downloadCSV}
            >
              <DownloadIcon />
              Download CSV File
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Paper elevation={2} sx={{ p: 3, borderRadius: 2, marginTop: "2%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="From Date"
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="To Date"
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: "3%" }}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gap={2}
            >
              <Button
                onClick={handleSearch}
                sx={{
                  backgroundColor: "#027BFE",
                  color: "white",
                  height: "56px",
                  textTransform: "none",
                  borderRadius: "10px",
                  width: "120px",
                }}
              >
                Search
              </Button>
              <Button
                onClick={() => handleClear()}
                sx={{
                  backgroundColor: "gray",
                  color: "white",
                  height: "56px",
                  textTransform: "none",
                  borderRadius: "10px",
                  width: "120px",
                }}
              >
                Clear
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Grid container style={{ marginTop: "3%" }}>
        <Grid
          style={{
            display: "flex",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            maxWidth: "30%",
            borderRadius: 10,
            border: "1px solid rgba(0, 0, 0, 0.3)",
            padding: "1%",
            justifyContent: "center",
            marginBottom: "2%",
          }}
        >
          <InputBase
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch(); // Trigger search when Enter is pressed
              }
            }}
            fullWidth
            style={{ paddingLeft: "3%" }}
          />
          <IconButton backgroundColor="textSecondary" onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Grid>
        <TableContainer
          component={Paper}
          sx={{
            maxWidth: "100%",
            overflowX: "auto",
            borderRadius: "12px 12px 0px 0px",
          }}
        >
          <Table>
            <TableHead style={{ backgroundColor: "#027BFE", color: "white" }}>
              <TableRow>
                <TableCell style={{ color: "white" }} align="start">
                  Sr. No.
                </TableCell>
                <TableCell style={{ color: "white" }} align="start">
                  Txn ID
                </TableCell>
                <TableCell style={{ color: "white" }} align="start">
                  Utr No.
                </TableCell>
                <TableCell style={{ color: "white" }} align="start">
                  Account No.
                </TableCell>
                <TableCell style={{ color: "white" }} align="start">
                  Account Name
                </TableCell>
                <TableCell style={{ color: "white" }} align="start">
                  Date
                </TableCell>
                <TableCell style={{ color: "white" }} align="start">
                  Opening Balance
                </TableCell>
                <TableCell style={{ color: "white" }} align="start">
                  Amount
                </TableCell>
                <TableCell style={{ color: "white" }} align="start">
                  Closing Balance
                </TableCell>
                <TableCell style={{ color: "white" }} align="start">
                  Status
                </TableCell>
                <TableCell style={{ color: "white" }} align="center">
                  Invoice
                </TableCell>
                {/* <TableCell style={{ color: 'white' }} align="start">Surcharge</TableCell>
                                <TableCell style={{ color: 'white' }} align="start">Total Amount</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.length > 0 ? (
                paginatedData.map((row, index) => (
                  <TableRow
                    key={index}
                    className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                  >
                    <TableCell align="start">
                      {index + 1 + (page - 1) * rowsPerPage}
                    </TableCell>{" "}
                    {/* Adjusted serial number */}
                    <TableCell align="start">
                      {row.txnReferenceId || "N/A"}
                    </TableCell>
                    <TableCell align="start">
                      {row.externalRef || "N/A"}
                    </TableCell>
                    <TableCell align="start">
                      {row.payeeAccount || "N/A"}
                    </TableCell>
                    <TableCell align="start">
                      {row.payeeName || "N/A"}
                    </TableCell>
                    {/* Status with conditional rendering and colors */}
                    <TableCell align="start">
                      {formatDate(row.timestamp)}
                    </TableCell>
                    <TableCell align="start">
                      {row.walletOpeningBalance !== null
                        ? row.walletOpeningBalance.toFixed(2)
                        : "N/A"}
                    </TableCell>
                    <TableCell align="start">{row.txnValue || "N/A"}</TableCell>
                    <TableCell align="start">
                      {row.walletClosingBalance !== null
                        ? row.walletClosingBalance.toFixed(2)
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      align="start"
                      style={{
                        color:
                          row.status === "Transaction Successful"
                            ? "green"
                            : row.status === "Transaction Pending"
                              ? "#B59410"
                              : row.status === "REFUND"
                                ? "#754668"
                                : row.status === "Transaction Failed"
                                  ? "red"
                                  : "inherit", // Default text color
                        fontWeight: "bold",
                      }}
                    >
                      {row.status === "Transaction Successful"
                        ? "Successful"
                        : row.status === "Transaction Pending"
                          ? "Pending"
                          : row.status === "REFUND"
                            ? "Refund"
                            : row.status === "Transaction Failed"
                              ? "Failed"
                              : row.status || "N/A"}{" "}
                      {/* Default value if no match */}
                    </TableCell>
                    <TableCell align="center">
                      <DownloadIcon1
                        onClick={() => handleDownloadPDF(row)} // Trigger PDF download
                        style={{ cursor: "pointer" }}
                      />
                    </TableCell>
                    {/* Off-screen Receipt Component for rendering as PDF */}
                    <Grid
                      style={{
                        position: "absolute",
                        left: "-9999px",
                        top: "0px",
                      }}
                    >
                      <Grid2 id={`receipt-${row.txnReferenceId}`}>
                        <PayoutReportInvoice data={row} />
                      </Grid2>
                    </Grid>
                    {/* <TableCell align="start">{row.poolAmount || 'N/A'}</TableCell>
                                    <TableCell align="start">{row.txnValue && row.poolAmount ? `${Number(row.txnValue) + Number(row.poolAmount)}` : 'N/A'}</TableCell> */}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={16} align="center">
                    No Data Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          count={Math.ceil(filteredData.length / rowsPerPage)} // Total pages based on filtered data
          page={page} // Use the existing page state
          onChange={handleChangePage} // Simplified event handler
          color="primary"
          variant="outlined"
          shape="rounded"
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
            marginLeft: "35%",
          }}
        />
      </Grid>
    </Container>
  );
};

export default NewPayOutReport;
