import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Paper,
} from "@mui/material";

const NewFixCom = () => {
  const [partnerCommissions, setPartnerCommissions] = useState({});
  const [selectedRoleType, setSelectedRoleType] = useState("");
  const [commissionPercentage, setCommissionPercentage] = useState("");
  const [commissionSet, setCommissionSet] = useState(false);
  const [commissionViewed, setCommissionViewed] = useState(false);

  const handleCommissionChange = (partner, value) => {
    setPartnerCommissions((prevState) => ({
      ...prevState,
      [partner]: value,
    }));
  };

  const handleRoleTypeSelect = (event) => {
    setSelectedRoleType(event.target.value);
  };

  const handleCommissionPercentageChange = (event) => {
    setCommissionPercentage(event.target.value);
  };

  const handleSetButtonClick = async () => {
    setCommissionSet(true);
    handleCommissionChange(selectedRoleType, commissionPercentage);

    const token = localStorage.getItem("jwtToken"); // Retrieve JWT token from local storage

    try {
      await axios.put(
        "https://testapi.ipaisa.co.in/myapp/admin/addcommission",
        { userRole: selectedRoleType, commission: commissionPercentage },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add Bearer token to the headers
          },
        }
      );

      // Reset the form fields after successful API call
      setSelectedRoleType("");
      setCommissionPercentage("");
    } catch (error) {
      console.error("Error setting commission:", error);
      // Handle error (show error message, etc.)
    }
  };

  const handleViewButtonClick = () => {
    setCommissionViewed(true);
  };

  const handleHideButtonClick = () => {
    setCommissionViewed(false);
  };

  const handleCancel = () => {
    setSelectedRoleType("");
    setCommissionPercentage("");
  };

  const lineHeight = "50px";

  return (
    <Grid>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <InputLabel>Select Partner Type</InputLabel>
              <Select
                label="Select Partner Type"
                onChange={handleRoleTypeSelect}
                value={selectedRoleType}
              >
                <MenuItem value="CHANNELPARTNER">Channel Partner</MenuItem>
                <MenuItem value="SUPERDISTRIBUTOR">Super Distributor</MenuItem>
                <MenuItem value="MASTERDISTRIBUTOR">
                  Master Distributor
                </MenuItem>
                <MenuItem value="AREADISTRIBUTOR">Area Distributor</MenuItem>
                <MenuItem value="RETAILER">Retailer</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Percentage (%)"
              type="text"
              value={commissionPercentage}
              onChange={handleCommissionPercentageChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="end"
              gap={1}
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              <Button
                sx={{
                  border: "1.5px solid #FF0000",
                  height: lineHeight,
                  fontSize: "16px",
                  borderRadius: "8px",
                  color: "#FF0000",
                  textTransform: "none",
                  width: { xs: "100%", md: "18%" },
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  height: lineHeight,
                  fontSize: "16px",
                  textTransform: "none",
                  width: { xs: "100%", md: "18%" },
                  borderRadius: "8px",
                  backgroundColor: "#027BFE",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#027BFE",
                    color: "#FFFFFF",
                  },
                }}
                onClick={handleSetButtonClick}
              >
                Submit
              </Button>
            </Box>
          </Grid>
          {commissionSet && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={
                  commissionViewed
                    ? handleHideButtonClick
                    : handleViewButtonClick
                }
                fullWidth
              >
                {commissionViewed ? "Hide" : "View"}
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
      {commissionViewed &&
        Object.entries(partnerCommissions).map(
          ([userRole, commission], index) => (
            <Card key={index} variant="outlined" style={{ marginTop: "10px" }}>
              <CardContent>
                <Typography variant="h5" align="center" gutterBottom>
                  {userRole}
                </Typography>
                <Typography variant="body1" align="center">
                  Commission: {commission}%
                </Typography>
              </CardContent>
            </Card>
          )
        )}
    </Grid>
  );
};

export default NewFixCom;
