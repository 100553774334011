import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  Grid,
  Box,
  Button,
  Typography,
  Link,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  CircularProgress,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";

const UploadBox = styled(Box)({
  border: "2px dashed #ccc",
  borderRadius: "8px",
  padding: "16px",
  textAlign: "center",
  width: "100%",
  height: "150px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
});

const Preview = styled("iframe")({
  width: "100%",
  height: "100%",
  border: "none",
  borderRadius: "8px",
});

const NewKycVerification = () => {
  const theme = useTheme();
  const [documents, setDocuments] = useState({
    photo: null,
    pan: null,
    aadhaar: null,
    agreement: null,
  });

  const [pendingDocument, setPendingDocument] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (file) => {
    if (!file) return;

    setIsUploading(true);
    if (file.size <= 4 * 1024 * 1024) {
      // Check file size (4 MB)
      const reader = new FileReader();
      reader.onloadend = () => {
        setDocuments((prevDocs) => ({
          ...prevDocs,
          [pendingDocument]: reader.result,
        }));
        setPendingDocument("");
        setIsUploading(false);
      };
      reader.readAsDataURL(file);
    } else {
      setIsUploading(false);
      alert("Please upload a file smaller than 4 MB.");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        handleFileChange(acceptedFiles[0]);
      }
    },
    accept: {
      "application/pdf": [], // Accept PDFs
      "image/*": [], // Accept all image formats
    },
    maxSize: 4 * 1024 * 1024, // 4 MB limit
  });

  const handlePendingDocumentChange = (event) => {
    setPendingDocument(event.target.value);
  };

  const handleDelete = (type) => {
    setDocuments((prevDocs) => ({
      ...prevDocs,
      [type]: null,
    }));
  };

  const remainingDocuments = Object.keys(documents).filter(
    (type) => !documents[type]
  );

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(documents)) {
      if (value) {
        const file = dataURLtoFile(value, `${key}.pdf`); // Change this if needed based on file type
        formData.append(key, file);
      }
    }

    try {
      const token = localStorage.getItem("jwtToken");
      if (!token) {
        console.error("No JWT token found");
        return;
      }

      const response = await axios.post(
        "https://testapi.ipaisa.co.in/myapp/auth/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Files uploaded successfully");
      } else {
        console.error("Failed to upload files");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const handleCancel = () => {
    setDocuments({
      photo: null,
      pan: null,
      aadhaar: null,
      agreement: null,
    });
    setPendingDocument("");
  };

  const lineHeight = "56px";

  return (
    <Container
      sx={{
        padding: { xs: "1rem", md: "2rem" },
        marginTop: { xs: "2rem", md: "calc(1% + 56px)" },
      }}
    >
      <Grid style={{ marginTop: "3%" }}>
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "sans-serif",
            fontSize: "20px",
            color: "#343C6A",
          }}
        >
          KYC Verification
        </Typography>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: "3%" }}>
        <Grid item xs={12}>
          <Paper
            sx={{ padding: { xs: "1rem", md: "2rem" }, borderRadius: "10px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <UploadBox {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isUploading ? (
                    <CircularProgress />
                  ) : (
                    <Typography variant="body1">
                      Drag & drop a PDF or image file here, or click to select a
                      file
                    </Typography>
                  )}
                </UploadBox>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth>
                  <InputLabel id="pending-document-label">
                    Select Document
                  </InputLabel>
                  <Select
                    labelId="pending-document-label"
                    id="pending-document"
                    value={pendingDocument}
                    label="Select Document"
                    onChange={handlePendingDocumentChange}
                  >
                    {remainingDocuments.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  component="span"
                  disabled={!pendingDocument || isUploading}
                  onClick={() => document.querySelector("#file-upload").click()}
                  sx={{
                    backgroundColor:
                      !pendingDocument || isUploading ? "default" : "#027BFE",
                    "&:hover": {
                      backgroundColor:
                        !pendingDocument || isUploading ? "default" : "#0263d4",
                    },
                    width: "32.4%",
                    marginTop: { xs: "1rem", md: 0 },
                  }}
                >
                  Upload
                </Button>
                <input
                  id="file-upload"
                  accept="application/pdf,image/*" // Accept PDF and all image formats
                  style={{ display: "none" }}
                  type="file"
                  onChange={(event) => handleFileChange(event.target.files[0])}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  sx={{ color: theme.palette.text.disabled, fontSize: "16px" }}
                >
                  Uploaded Documents
                </Typography>
                <Grid container spacing={2}>
                  {Object.keys(documents).map(
                    (type) =>
                      documents[type] && (
                        <Grid item xs={12} sm={6} md={3} key={type}>
                          <Box
                            textAlign="center"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ marginBottom: 1 }}
                            >
                              {type.charAt(0).toUpperCase() + type.slice(1)}
                              <IconButton onClick={() => handleDelete(type)}>
                                <DeleteIcon />
                              </IconButton>
                            </Typography>
                            <Preview
                              src={documents[type]}
                              title={`${type} preview`}
                              sx={{
                                height: "120px",
                                width: "100%",
                                borderRadius: "8px",
                                objectFit: "cover",
                              }}
                            />
                            <Link
                              href={documents[type]}
                              download={`${type}.pdf`}
                              variant="body2"
                              sx={{ marginTop: 1 }}
                            >
                              Download{" "}
                              {type.charAt(0).toUpperCase() + type.slice(1)}
                            </Link>
                          </Box>
                        </Grid>
                      )
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="start"
                  gap={1}
                  sx={{ flexDirection: { xs: "column", md: "row" } }}
                >
                  <Button
                    sx={{
                      border: "1.5px solid #FF0000",
                      height: lineHeight,
                      fontWeight: "bold",
                      color: "#FF0000",
                      textTransform: "none",
                      width: { xs: "100%", md: "16%" },
                      borderRadius: "9px",
                    }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "#027BFE",
                      color: "white",
                      fontWeight: "bold",
                      textTransform: "none",
                      width: { xs: "100%", md: "16%" },
                      borderRadius: "9px",
                      height: lineHeight,
                      "&:hover": {
                        backgroundColor: "#0263d4",
                      },
                    }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewKycVerification;
