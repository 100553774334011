import React from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import PaymentRTopComp from "./PaymentRTopComp";
import { PlusIcon } from "../res/NewWebIcons";

const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .required("Amount is required")
    .positive("Amount must be positive"),
  bankAccount: Yup.string().required("Bank Account is required"),
  paymentMode: Yup.string().required("Payment Mode is required"),
  utrNumber: Yup.number()
    .required("UTR Number is required")
    .positive("UTR Number must be positive"),
  bankReceipt: Yup.mixed().required("Bank Receipt is required"),
});

const NewPaymentRequest = () => {
  const handleNumberChange = (e) => {
    const { id, value } = e.target;
    const newValue = value.replace(/\D/g, ""); // Replace any non-digit character with an empty string
    formik.setFieldValue(id, newValue);
  };

  const formik = useFormik({
    initialValues: {
      amount: "",
      bankAccount: "",
      paymentMode: "",
      utrNumber: "",
      bankReceipt: null,
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("issueType", "offlinebanksissue");
      formData.append("amount", values.amount);
      formData.append("bankAccount", values.bankAccount);
      formData.append("paymentMode", values.paymentMode);
      formData.append("utrNumber", values.utrNumber);
      formData.append("bankReceipt", values.bankReceipt);

      try {
        const token = localStorage.getItem("jwtToken");
        const response = await axios.post(
          "https://testapi.ipaisa.co.in/myapp/auth/create",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        resetForm();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const areRequiredFieldsFilled =
    formik.values.amount &&
    formik.values.bankAccount &&
    formik.values.paymentMode &&
    formik.values.utrNumber &&
    !formik.errors.amount &&
    !formik.errors.bankAccount &&
    !formik.errors.paymentMode &&
    !formik.errors.utrNumber;

  const lineHeight = "50px";

  return (
    <Container style={{ padding: "2rem", marginTop: "calc(3% + 56px)" }}>
      <Grid>
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1%",
          }}
        >
          <Grid>
            <Typography
              style={{
                fontWeight: "bold",
                fontFamily: "sans-serif",
                fontSize: "19px",
                color: "#343C6A",
              }}
            >
              Payment Request
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid style={{ marginTop: "3%" }}>
        <Paper
          sx={{ padding: { xs: "1rem", md: "2rem" }, borderRadius: "8px" }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="amount"
                  name="amount"
                  label="Amount"
                  value={formik.values.amount}
                  onChange={handleNumberChange}
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  placeholder="Enter amount"
                  error={formik.touched.amount && Boolean(formik.errors.amount)}
                  helperText={formik.touched.amount && formik.errors.amount}
                  sx={{ marginBottom: "2%" }} // Margin between fields
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.bankAccount &&
                    Boolean(formik.errors.bankAccount)
                  }
                  sx={{ marginBottom: "2%" }}
                >
                  <InputLabel id="bank-account-label">Bank Account</InputLabel>
                  <Select
                    labelId="bank-account-label"
                    id="bankAccount"
                    name="bankAccount"
                    value={formik.values.bankAccount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="Bank Account"
                  >
                    <MenuItem value="account1">Yes Bank</MenuItem>
                    {/* <MenuItem value="account2">SBI Bank</MenuItem>
                                        <MenuItem value="account3">HDFC</MenuItem> */}
                  </Select>
                  {formik.touched.bankAccount && formik.errors.bankAccount && (
                    <Typography color="error">
                      {formik.errors.bankAccount}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.paymentMode &&
                    Boolean(formik.errors.paymentMode)
                  }
                  sx={{ marginBottom: "2%" }}
                >
                  <InputLabel id="payment-mode-label">Payment Mode</InputLabel>
                  <Select
                    labelId="payment-mode-label"
                    id="paymentMode"
                    name="paymentMode"
                    value={formik.values.paymentMode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="Payment Mode"
                  >
                    <MenuItem value="mode1">NEFT/RTGS</MenuItem>
                    <MenuItem value="mode2">IMPS</MenuItem>
                  </Select>
                  {formik.touched.paymentMode && formik.errors.paymentMode && (
                    <Typography color="error">
                      {formik.errors.paymentMode}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="utrNumber"
                  name="utrNumber"
                  label="UTR Number"
                  value={formik.values.utrNumber}
                  onChange={handleNumberChange}
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  placeholder="Enter UTR number"
                  error={
                    formik.touched.utrNumber && Boolean(formik.errors.utrNumber)
                  }
                  helperText={
                    formik.touched.utrNumber && formik.errors.utrNumber
                  }
                  sx={{ marginBottom: "2%" }} // Margin between fields
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  sx={{
                    height: lineHeight,
                    width: "100%",
                    fontSize: { xs: "8px", md: "15px" },
                    textTransform: "none",
                    borderRadius: "8px",
                    fontWeight: "bold",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #027BFE",
                    color: "#027BFE",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                      color: "#027BFE",
                    },
                    gap: "4%",
                  }}
                  component="label"
                  fullWidth
                >
                  Upload Bank Receipt
                  <PlusIcon />
                  <input
                    type="file"
                    name="bankReceipt"
                    hidden
                    onChange={(event) => {
                      formik.setFieldValue(
                        "bankReceipt",
                        event.currentTarget.files[0]
                      );
                    }}
                    disabled={!areRequiredFieldsFilled}
                  />
                </Button>
                {formik.values.bankReceipt && (
                  <Typography variant="body1">
                    Selected File: {formik.values.bankReceipt.name}
                  </Typography>
                )}
                {formik.touched.bankReceipt && formik.errors.bankReceipt && (
                  <Typography color="error">
                    {formik.errors.bankReceipt}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sx={{ marginTop: { xs: "0%", md: "1%" } }}>
                <Box
                  display="flex"
                  justifyContent="end"
                  gap={1}
                  sx={{ flexDirection: { xs: "column", md: "row" } }}
                >
                  <Button
                    onClick={formik.handleReset}
                    sx={{
                      border: "1.5px solid #FF0000",
                      color: "#FF0000",
                      textTransform: "none",
                      fontSize: "16px",
                      height: lineHeight,
                      width: { xs: "100%", md: "16%" },
                      borderRadius: "9px",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: "#027BFE",
                      color: "white",
                      fontSize: "16px",
                      height: lineHeight,
                      textTransform: "none",
                      width: { xs: "100%", md: "16%" },
                      "&:hover": {
                        backgroundColor: "#027BFE",
                        color: "#FFFFFF",
                      },
                      borderRadius: "9px",
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Container>
  );
};

export default NewPaymentRequest;
