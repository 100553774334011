// redux/actions/walletActions.js
import axios from "axios";
import { SET_WALLET_BALANCE, SET_ERROR } from "../actionTypes";

export const fetchWalletBalance = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://testapi.ipaisa.co.in/myapp/auth/getwalletbalance",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: SET_WALLET_BALANCE,
        payload: response.data.walletBalance || 0,
      });
      console.log("walletBalance", response.data.walletBalance);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
      dispatch({ type: SET_ERROR, payload: "Failed to fetch wallet balance" });
    }
  };
};
