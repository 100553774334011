import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const DeepLinkPage = () => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [decryptedData, setDecryptedData] = useState(null);

  // Fetch wallet balance from API
  const fetchWalletBalance = async () => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://testapi.ipaisa.co.in/myapp/auth/getwalletbalance",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWalletBalance(response.data.walletBalance);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  useEffect(() => {
    fetchWalletBalance();

    // Extract encrypted data from the URL
    const path = window.location.pathname;
    const encryptedData = path.replace("/deeplinkpage/", "");

    if (encryptedData) {
      decryptPayload(encryptedData);
    }
  }, []);

  const decryptPayload = async (encryptedData) => {
    try {
      const response = await axios.post(
        "https://testapi.ipaisa.co.in/myapp/pg/decryptPayload",
        { data: encryptedData },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === "Success") {
        const decrypted = response.data.data;
        setDecryptedData(decrypted);
        formik.setFieldValue("amount", decrypted.amount);
      } else {
        console.error("Failed to decrypt data:", response.data.message);
      }
    } catch (error) {
      console.error("Error decrypting data:", error);
    }
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError("Amount must be a number")
      .positive("Amount must be positive")
      .required("Amount is required"),
    remark: Yup.string().required("Remark is required"),
  });

  const formik = useFormik({
    initialValues: {
      amount: decryptedData?.amount || "",
      remark: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const token = localStorage.getItem("jwtToken");
        const response = await axios.post(
          "https://ipaisa.co.in/api/protected/add-money",
          values,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // toast.success('Money added successfully');
        formik.resetForm();
        initiatePayment();
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "Error adding amount";
        toast.error(errorMessage);
      }
    },
  });

  const initiatePayment = async () => {
    try {
      const paymentData = {
        txnid: decryptedData?.txnid,
        amount: formik.values.amount,
        productinfo: "Add Money",
        firstname: "Edsom",
        phone: decryptedData?.phone,
        email: "edsomfintech@gmail.com",
        surl: "https://ipaisa.co.in/transactionsuccess",
        furl: "https://ipaisa.co.in/transactionfailed",
      };

      const token = localStorage.getItem("jwtToken");
      const response = await axios.post(
        "https://testapi.ipaisa.co.in/myapp/ezpg/easebuzz/initiate-payment",
        paymentData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseBody = response.data.body;
      const parsedBody = JSON.parse(responseBody);

      if (parsedBody.status === 1) {
        const accessKey = parsedBody.data;
        const paymentUrl = `https://pay.easebuzz.in/pay/${accessKey}`;
        const newWindow = window.open(paymentUrl, "_blank");

        if (newWindow) {
          const checkWindowUrl = setInterval(() => {
            try {
              const currentUrl = newWindow.location.href;
              const successUrl = "https://ipaisa.co.in/signin";
              const failureUrl = "https://ipaisa.co.in/signin";

              if (currentUrl === successUrl || currentUrl === failureUrl) {
                clearInterval(checkWindowUrl);
                newWindow.close();
                setTimeout(() => {
                  handlePaymentStatus(paymentData.txnid);
                }, 1000);
              }
            } catch (e) {
              // Catch and ignore cross-origin errors when accessing the new window's URL
              // This will happen if the new window is redirected to a different domain
            }
          }, 1000);

          // Close the new window after 2 minutes if it hasn't been closed
          setTimeout(() => {
            if (!newWindow.closed) {
              newWindow.close();
              console.log("Payment window closed after 2 minutes");
            }
          }, 120000);
        }
      } else {
        console.error("Access key not received from backend");
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  const handlePaymentStatus = async (txnid) => {
    try {
      const token = localStorage.getItem("jwtToken");

      const response = await axios.post(
        "https://testapi.ipaisa.co.in/myapp/ezpg/transaction-api-v2",
        { txnid }
      );
      handlePaymentSuccess(response.data);
      fetchWalletBalance();
    } catch (error) {
      console.error("Error verifying payment:", error);
      handlePaymentSuccess({ status: 0, message: "Error verifying payment" });
    }
  };

  const handlePaymentSuccess = (response) => {
    console.log("Payment success response:", response.msg.status);
    if (response.msg.status === "userCancelled") {
    }
    if (response.status === 1) {
      toast.success("Payment successful");
    } else {
      toast.error("Payment failed: " + response.message);
    }
  };

  return (
    <div style={{ height: "100vh", backgroundColor: "#F2F5FA" }}>
      <Container
        maxWidth="lg"
        style={{
          width: "90%",
          marginTop: "7%",
          padding: "2%",
          height: "80%",
          overflow: "hidden",
        }}
      >
        <Paper
          elevation={3}
          style={{ padding: "2%", borderRadius: "12px", height: "70%" }}
        >
          <Grid container alignItems="center" justifyContent="space-evenly">
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                gutterBottom
                style={{ fontSize: "24px", fontWeight: "bold", color: "black" }}
              >
                Add Money
              </Typography>
              <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h7">Amount</Typography>
                    <TextField
                      name="amount"
                      type="number"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      fullWidth
                      required
                      InputLabelProps={{
                        style: { height: "2.5em" },
                      }}
                      error={
                        formik.touched.amount && Boolean(formik.errors.amount)
                      }
                      helperText={formik.touched.amount && formik.errors.amount}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h7">Remark</Typography>
                    <TextField
                      name="remark"
                      value={formik.values.remark}
                      onChange={formik.handleChange}
                      fullWidth
                      required
                      multiline
                      rows={4}
                      InputLabelProps={{
                        style: { height: "3.5em" },
                      }}
                      error={
                        formik.touched.remark && Boolean(formik.errors.remark)
                      }
                      helperText={formik.touched.remark && formik.errors.remark}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      style={{
                        textAlign: "center",
                        color: "#D9D9D9",
                        width: "100%",
                        height: "60px",
                        borderRadius: "12px",
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<AddCircleOutlineIcon />}
                    >
                      Add Money {formik.values.amount}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default DeepLinkPage;
