import React, { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const forgotMpinSchema = Yup.object({
  mobileNumber: Yup.string()
    .required("Mobile Number is required")
    .matches(/^\d+$/, "Mobile number must be digits only")
    .length(10, "Mobile number must be exactly 10 digits"),
  newMpin: Yup.string()
    .required("New MPIN is required")
    .matches(/^\d{0,4}$/, "MPIN must be 0-4 digits long")
    .max(4, "MPIN must be at most 4 digits long"),
  confirmMpin: Yup.string()
    .oneOf([Yup.ref("newMpin"), null], "MPINs must match")
    .required("Confirm MPIN is required"),
});

const ForgotMpin = ({ handleMpinSuccess }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMobileValid, setIsMobileValid] = useState(null);
  const [isSuccess, setIsSuccess] = useState(null);
  const navigate = useNavigate();

  const handleMpin = () => {
    if (isSuccess) {
      handleMpinSuccess();
    }
  };

  const handleInputChange = async (e, setFieldValue) => {
    const { name, value } = e.target;
    setFieldValue(name, value);

    if (name === "mobileNumber" && value.length === 10) {
      try {
        const response = await axios.post(
          "https://testapi.ipaisa.co.in/myapp/auth/validatemobilenumber",
          { mobileno: value }
        );
        setIsMobileValid(response.data.mobileValidate ? true : false);
        if (response.data.mobileValidate) {
          toast.success("Mobile number is valid");
        } else {
          toast.error("Mobile number is already registered");
        }
      } catch (error) {
        console.error("Error validating mobile number:", error);
        setIsMobileValid(false);
        toast.error("Error validating mobile number");
      }
    }
  };

  const handleForgotMpin = async (values, { setSubmitting }) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        "https://testapi.ipaisa.co.in/myapp/auth/setmpin",
        { mobileno: values.mobileNumber, mpin: values.newMpin }
      );
      if (response.data.success) {
        setIsSuccess(response.data.success);
        toast.success("MPIN reset successfully");
        navigate("/signin");
      } else {
        toast.error("Error resetting MPIN");
      }
    } catch (error) {
      console.error("Error resetting MPIN:", error);
      toast.error("Error resetting MPIN");
    } finally {
      setIsSubmitting(false);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ mobileNumber: "", newMpin: "", confirmMpin: "" }}
      validationSchema={forgotMpinSchema}
      onSubmit={handleForgotMpin}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form style={{ width: "100%" }} autoComplete="off">
          <Field
            as={TextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="mobileNumber"
            label="Mobile Number"
            name="mobileNumber"
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            helperText={<ErrorMessage name="mobileNumber" />}
            error={touched.mobileNumber && Boolean(errors.mobileNumber)}
            onChange={(e) => handleInputChange(e, setFieldValue)}
          />
          <Field
            as={TextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="newMpin"
            label="New MPIN"
            name="newMpin"
            type="password"
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            helperText={<ErrorMessage name="newMpin" />}
            error={touched.newMpin && Boolean(errors.newMpin)}
          />
          <Field
            as={TextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="confirmMpin"
            label="Confirm MPIN"
            name="confirmMpin"
            type="password"
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            helperText={<ErrorMessage name="confirmMpin" />}
            error={touched.confirmMpin && Boolean(errors.confirmMpin)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{
              height: "50px",
              backgroundColor: "#0078FF",
              borderRadius: "5px",
              fontWeight: "1000",
              textTransform: "none",
            }}
            disabled={isSubmitting || isMobileValid === false}
            onClick={handleMpin}
          >
            Reset MPIN
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotMpin;
