import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import WalletBalanceComponent from "./WalletBalanceComponent";
import { PartnerArrowIcon } from "../../res/NewWebIcons";
import MoneyAddedSucc from "./MoneyAddedSucc";
import MoneyFailedtoAdd from "./MoneyFailedtoAdd";
import axios from "axios";
import IpinModal from "../IpaisaMpin/IpinModal";
import SetPasswordModal from "../IpaisaMpin/SetIPMpin"; // Import the modal component

const AddMoneyNew = () => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [txnid, setTxnid] = useState("");
  const [moneyaddedpop, setMoneyaddedpop] = useState(false);
  const [moneyfailedtoadd, setMoneyfailedtoadd] = useState(false);
  const [openIpinModal, setOpenIpinModal] = useState(false);
  const [formValues, setFormValues] = useState({ amount: "", remark: "" });
  const [openSetPasswordModal, setOpenSetPasswordModal] = useState(false); // State for SetPassword modal

  const handleOpenModal = () => {
    const isTxnMpinSet = localStorage.getItem("isTxnMpinSet");

    if (isTxnMpinSet === "False") {
      toast.error("Please set IPin first.");
      setTimeout(() => {
        setOpenSetPasswordModal(true); // Open SetPasswordModal after delay
      }, 3000);
    } else {
      setOpenIpinModal(true); // Open IPinModal if TxnMpin is set
    }
  };

  const handleClose = () => {
    setMoneyaddedpop(false);
    setMoneyfailedtoadd(false);
    setOpenIpinModal(false);
    setOpenSetPasswordModal(false); // Close the SetPasswordModal
  };

  const fetchWalletBalance = async () => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://testapi.ipaisa.co.in/myapp/auth/getwalletbalance",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWalletBalance(response.data.walletBalance);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  useEffect(() => {
    fetchWalletBalance();
  }, []);

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError("Amount must be a number")
      .positive("Amount must be positive")
      .required("Amount is required"),
    remark: Yup.string().required("Remark is required"),
  });

  const formik = useFormik({
    initialValues: {
      amount: "",
      remark: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setFormValues(values); // Store form values
      handleOpenModal(); // Trigger the modal
    },
  });

  const initiatePayment = async (values) => {
    try {
      const paymentData = {
        txnid: "iPaisa" + new Date().getTime(),
        amount: values.amount,
        productinfo: "Add Money",
        firstname: "Edsom",
        phone: "8378054080",
        email: "edsomfintech@gmail.com",
        surl: "https://ipaisa.co.in/signin",
        furl: "https://ipaisa.co.in/signin",
      };

      setTxnid(paymentData.txnid);

      const token = localStorage.getItem("jwtToken");
      const response = await axios.post(
        "https://testapi.ipaisa.co.in/myapp/auth/easebuzz/initiate-payment",
        paymentData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status === 1) {
        const accessKey = response.data.data;
        const paymentUrl = `https://pay.easebuzz.in/pay/${accessKey}`;
        const newWindow = window.open(paymentUrl, "_blank");

        if (newWindow) {
          newWindow.focus();

          const checkWindowLocation = setInterval(() => {
            try {
              if (newWindow.location.href === "https://ipaisa.co.in/signin") {
                clearInterval(checkWindowLocation);
                newWindow.close();
                handlePaymentStatus(paymentData.txnid);
              }
            } catch (e) {
              console.error("Cross-origin error:", e);
            }
          }, 1000);
        }
      } else {
        toast.error("Failed to initiate payment");
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  const handleConfirmPayment = async () => {
    setTimeout(async () => {
      await initiatePayment(formValues); // Proceed with payment after delay
      setOpenIpinModal(false); // Close the modal after payment initiation
    }, 1000);
  };

  // const handlePaymentStatus = async (txnid) => {
  //   try {
  //     const token = localStorage.getItem("jwtToken");
  //     const response = await axios.post(
  //       "https://testapi.ipaisa.co.in/myapp/auth/transaction-api-v2",
  //       { txnid },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       const { status, message } = response.data;

  //       if (status.toLowerCase().includes("success")) {
  //         toast.success("Funds Added Successfully");
  //         setMoneyaddedpop(true);
  //         setMoneyfailedtoadd(false);
  //       } else {
  //         toast.error("Payment failed: " + (message || "Unknown error"));
  //         setMoneyfailedtoadd(true);
  //         setMoneyaddedpop(false); // Hide success popup if shown previously
  //       }

  //       // Update wallet balance after the transaction
  //       await fetchWalletBalance();
  //     } else {
  //       toast.error("Payment failed: " + (response.data.message || "Unknown error"));
  //       setMoneyfailedtoadd(true);
  //       setMoneyaddedpop(false);
  //     }
  //   } catch (error) {
  //     console.error("Error verifying payment:", error);
  //     setMoneyfailedtoadd(true);
  //     setMoneyaddedpop(false);
  //     toast.error("An error occurred while verifying the payment.");
  //   }
  // };

  const handlePaymentStatus = async (txnid) => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.post(
        "https://testapi.ipaisa.co.in/myapp/auth/transaction-api-v2",
        { txnid },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data.successful) {
        toast.success("Funds Added Successfully");
        setMoneyaddedpop(true);
        setMoneyfailedtoadd(false);
      } else {
        toast.error(
          "Payment failed: " + (response.data.message || "Unknown error")
        );
        setMoneyfailedtoadd(true);
      }

      await fetchWalletBalance();
    } catch (error) {
      console.error("Error verifying payment:", error);
      setMoneyfailedtoadd(true);
      toast.error("An error occurred while verifying the payment.");
    }
  };

  const handleAmountChange = (e) => {
    const { name, value } = e.target;
    let validValue = value.replace(/[^0-9]/g, "");
    if (validValue.startsWith("0") && validValue.length > 1) {
      validValue = validValue.replace(/^0+/, "");
    }
    if (validValue === "0") {
      validValue = "";
    }
    formik.setFieldValue(name, validValue);
  };

  const inputHeight = "50px";

  return (
    <Container style={{ padding: "2rem", marginTop: "calc(3% + 56px)" }}>
      <Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1%",
            justifyContent: "start",
          }}
        >
          <Typography
            color="textSecondary"
            style={{ fontFamily: "sans-serif", fontSize: "13px" }}
          >
            Balance
          </Typography>
          <PartnerArrowIcon />
          <Typography
            color="textSecondary"
            style={{ fontFamily: "sans-serif", fontSize: "13px" }}
          >
            Add Money
          </Typography>
        </Grid>
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1%",
          }}
        >
          <Grid>
            <Typography
              style={{
                fontWeight: "bold",
                fontFamily: "sans-serif",
                fontSize: "19px",
                color: "#343C6A",
              }}
            >
              Add Money
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "3%" }}>
        <WalletBalanceComponent walletBalance={walletBalance} />
        <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
          <Paper style={{ padding: "2rem", borderRadius: "8px" }}>
            <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h7">Amount</Typography>
                  <TextField
                    name="amount"
                    type="text"
                    value={formik.values.amount}
                    onChange={handleAmountChange}
                    fullWidth
                    required
                    InputLabelProps={{ style: { height: "2.5em" } }}
                    error={
                      formik.touched.amount && Boolean(formik.errors.amount)
                    }
                    helperText={formik.touched.amount && formik.errors.amount}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h7">Remark</Typography>
                  <TextField
                    name="remark"
                    value={formik.values.remark}
                    onChange={formik.handleChange}
                    fullWidth
                    required
                    InputLabelProps={{ style: { height: "2.5em" } }}
                    error={
                      formik.touched.remark && Boolean(formik.errors.remark)
                    }
                    helperText={formik.touched.remark && formik.errors.remark}
                  />
                </Grid>

                <Grid item xs={12} style={{ marginTop: "6.5%" }}>
                  <Box display="flex" justifyContent="end" gap={1}>
                    <Button
                      sx={{
                        border: "1.5px solid #FF0000",
                        color: "#FF0000",
                        textTransform: "none",
                        width: "18%",
                        fontSize: "16px",
                        height: inputHeight,
                        borderRadius: "8px",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleOpenModal}
                      sx={{
                        backgroundColor: "#027BFE",
                        color: "white",
                        textTransform: "none",
                        width: "18%",
                        fontSize: "14px",
                        borderRadius: "8px",
                        height: inputHeight,
                      }}
                    >
                      Add Money
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
      {moneyaddedpop && (
        <MoneyAddedSucc open={moneyaddedpop} handleClose={handleClose} />
      )}
      {moneyfailedtoadd && (
        <MoneyFailedtoAdd open={moneyfailedtoadd} handleClose={handleClose} />
      )}
      {localStorage.getItem("isTxnMpinSet") === "False" ? (
        <SetPasswordModal
          open={openSetPasswordModal}
          onClose={() => setOpenSetPasswordModal(false)}
        />
      ) : (
        <IpinModal
          open={openIpinModal}
          onClose={handleClose}
          onMpinValid={handleConfirmPayment}
        />
      )}
    </Container>
  );
};

export default AddMoneyNew;
